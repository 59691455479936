<template>
  <div>
    <spacer :y="5"/>
    <stack-item>
      <contents-box title="メンバーシップ">
        <data-grid>
          <data-row
            v-for="row in membership"
            :key="row">
            <data-term :class="$style.label" width="quater">
              <label-box :label="row.label"/>
            </data-term>

            <data-detail>
              <div :class="$style.detail">
                <div>
                  <p v-if="row.name === 'password'">********</p>
                  <p v-else>{{ user[row.name] }}</p>
                </div>
                <router-link
                  v-if="row.name !== 'customer_id' && !row.hideEdit"
                  :to="row.link">
                  <div :class="$style.editBtn">更新する</div>
                </router-link>
              </div>
            </data-detail>
          </data-row>

          <!-- Virtual Cast ID は表示条件が少し特殊なため membership 配列の制御から外して個別実装します -->
          <data-row v-if="showVCID">
            <data-term :class="$style.label" width="quater">
              <label-box label="Virtual Cast ID"/>
            </data-term>
            <data-detail>
              <div :class="$style.detail">
                <div>
                  <p>{{ user.virtualCastProp.virtual_cast_id }}</p>
                  <p :class="$style.assistant_lessmargin">
                    以下のリンクがご自分のアカウントか確認してください。<br>
                    <a :href="getVirtualCastUserURL(user.virtualCastProp.virtual_cast_id)" target="_blank">{{ getVirtualCastUserURL(user.virtualCastProp.virtual_cast_id) }}</a>
                  </p>
                  <p :class="$style.assistant_lessmargin">万が一間違えて登録してしまった場合は営業日3日前までに事務局へ連絡ください。</p>
                </div>
              </div>
            </data-detail>
          </data-row>

        </data-grid>
      </contents-box>
    </stack-item>

    <stack-item>
      <contents-box title="詳細情報">
        <div :class="$style.wrapper">
          <div :class="$style.row">
            <div :class="$style.label_wrap">
              <p :class="$style.label_form">プロフィール画像</p>
              <div :class="$style.thumbnail_wrap">
                <div
                  v-if="!user.lti && previewUrl"
                  :class="$style.thumbnail"
                  :style="`background-image: url(${previewUrl})`"/>
                <div
                  v-else-if="!user.lti && !previewUrl"
                  :class="$style.thumbnail"
                  :style="`background-image: url(${getMyIconUrl(user)})`"/>
                <round-img-LTI
                  v-else-if="user.lti && previewUrl"
                  :src="previewUrl" />
                <round-img-LTI
                  v-else-if="user.lti && !previewUrl"
                  :src="getMyIconUrl(user)" />
                <input
                  type="file"
                  id="file"
                  accept=".jpg, .jpeg, .png, .gif"
                  v-on:change="updateFile">
                <label
                  for="file"
                  :class="$style.thumbnail_btn">選択する</label>
                <spacer :x="2"/>
                <input
                  type="button"
                  id="deleteFile"
                  v-on:click="deleteFile">
                <label
                  v-if="user.urls && user.urls.icon && user.urls.icon.length && user.urls.icon[0].url || file"
                  for="deleteFile"
                  :class="$style.thumbnail_btn">画像を削除する</label>
              </div>
              <p :class="$style.assistant">推奨サイズ：110 x 110<br>ファイルタイプ：.jpg, .jpeg, .png, .gif</p>
            </div>
          </div>
          <div
            :class="$style.row"
            v-for="row in userItems"
            :key="row.label">
            <div :class="$style.label_wrap">
              <p :class="$style.label_form">{{ row.label }}<span v-if="row.required" :class="$style.required">※必須項目</span></p>
            </div>
            <spacer :y="1" />
            <div class="n2br" :class="$style.detail" v-if="row.edit">
              <input
                v-if="row.type === 'text'"
                v-model="row.value"
                type="text"
                :class="$style.text">
              <textarea
                v-if="row.type === 'textarea'"
                rows="5"
                :class="$style.textarea"
                v-model="row.value"/>
            </div>
            <div v-else-if="helper.master.labels">
              <p v-if="row.name === 'birthday'">{{ formatTimestamp(user[row.name], 'YYYY年MM月DD日') }}</p>
              <p v-else-if="row.name === 'gender'">{{ helper.master.labels.users.gender[user.gender] }}</p>
              <p v-else v-html="user[row.name]"></p>
            </div>
          </div>
          <div :class="$style.row">
            <div :class="$style.label_wrap">
              <p :class="$style.label_form">住所<span :class="$style.required">※必須項目</span></p>
            </div>
            <spacer :y="1" />
            <div class="n2br" :class="$style.detail">
              <Address
                :items="items"
                :isConfirm="false"
                v-on:sendValue="receiveValue"
                ref="address"/>
            </div>
          </div>

          <btn-container>
            <basic-btn
              size="sm"
              tag="button"
              v-on:click="updateUser('base')">更新</basic-btn>
          </btn-container>
        </div>
      </contents-box>
    </stack-item>

    <stack-item v-if="user.account_type === 1 && user.school[0].name !== '個人向け'">
      <contents-box :title="user.school && user.school.length && user.school[0].is_company ? '利用者情報' : '学生情報'">
        <div :class="$style.wrapper">
          <div
            :class="$style.row"
            v-for="row in studentItems"
            :key="row.label">
            <div :class="$style.label_wrap">
              <p :class="$style.label_form">{{ user.school && user.school.length && user.school[0].is_company ? row.companyLabel : row.label }}<span v-if="row.required" :class="$style.required">※必須項目</span></p>
            </div>
            <spacer :y="1" />
            <div class="n2br" :class="$style.detail" v-if="row.edit">
              <input
                v-if="row.type === 'text'"
                v-model="row.value"
                type="text"
                :class="$style.text">
            </div>
            <div v-else-if="helper.master.labels">
              <p>{{ row.value || '未記載' }}</p>
            </div>
          </div>

          <div :class="$style.row" v-if="!is_company && user.account_type === 1 && user.school_year !== null">
            <div :class="$style.label_wrap">
              <p :class="$style.label_form">学年</p>
              <p>{{ user.school_year }}年生</p>
            </div>
          </div>

          <!-- 編集項目ないので -->
          <!-- <btn-container>
            <basic-btn
              size="sm"
              tag="button"
              v-on:click="updateUser('additional')">更新</basic-btn>
          </btn-container> -->
        </div>
      </contents-box>
    </stack-item>

    <stack-item v-if="user.account_type === 3">
      <contents-box :title="user.school && user.school.length && user.school[0].is_company ? '利用者情報' : '教職員情報'">
        <div :class="$style.wrapper">
          <div
            :class="$style.row"
            v-for="row in teacherItems"
            :key="row.label">
            <div :class="$style.label_wrap">
              <p :class="$style.label_form">{{ user.school && user.school.length && user.school[0].is_company ? row.companyLabel : row.label }}<span v-if="row.required" :class="$style.required">※必須項目</span></p>
            </div>
            <spacer :y="1" />
            <div class="n2br" :class="$style.detail" v-if="row.edit">
              <input
                v-if="row.type === 'text'"
                v-model="row.value"
                type="text"
                :class="$style.text">
            </div>
            <div v-else-if="helper.master.labels">
              <p>{{ row.value || '未記載' }}</p>
            </div>
          </div>
        </div>
      </contents-box>
    </stack-item>
    <spacer :y="5"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import cf from '@/mixins/commonFunctions';
import BasicBtn from '@/views/components/BasicBtn.vue';
import BtnContainer from '@/views/components/BtnContainer.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import StackItem from '@/views/components/StackItem.vue';
import LabelBox from '@/views/components/LabelBox.vue';
import Spacer from '@/views/components/Spacer.vue';
import { Address } from '@/views/components/parts/Form';
import {
  DataGrid, DataRow, DataTerm, DataDetail,
} from '@/views/components/DataGrid';
import RoundImgLTI from '@/views/components/RoundImg-LTI';

export default {
  name: 'account',
  components: {
    BasicBtn,
    BtnContainer,
    ContentsBox,
    StackItem,
    LabelBox,
    Spacer,
    DataGrid,
    DataRow,
    DataTerm,
    DataDetail,
    Address,
    RoundImgLTI,
  },
  mixins: [cf],
  data() {
    return {
      membership: [
        {
          name: 'customer_id',
          label: '利用者ID',
        },
        {
          name: 'email',
          link: '/edit/email/',
          label: 'メールアドレス',
        },
        {
          name: 'password',
          link: '/edit/password/',
          label: 'パスワード',
        },
      ],
      userItems: [
        {
          label: '名前',
          name: 'username',
          type: 'text',
          required: true,
          edit: false,
          value: null,
        },
        {
          label: '名前カナ',
          name: 'kana',
          type: 'text',
          required: true,
          edit: false,
          value: null,
        },
        {
          label: '生年月日',
          name: 'birthday',
        },
        {
          label: '性別',
          name: 'gender',
        },
        {
          label: '携帯番号',
          name: 'tel',
          type: 'text',
          edit: true,
          value: null,
        },
        // {
        //   label: 'ニックネーム',
        //   name: 'nickname',
        //   type: 'text',
        //   edit: true,
        //   value: null,
        // },
        // {
        //   label: 'ひとこと',
        //   name: 'comment',
        //   type: 'textarea',
        //   edit: true,
        //   value: null,
        // },
      ],
      studentItems: [
        // {
        //   label: '学籍番号',
        //   companyLabel: '特記事項',
        //   name: 'student_id',
        //   type: 'text',
        //   edit: false,
        //   value: null,
        // },
        // {
        //   label: 'school_year',
        //   name: '学年',
        //   type: 'text',
        //   edit: true,
        //   value: null,
        // },
      ],
      teacherItems: [
        // {
        //   name: 'route',
        //   type: 'text',
        //   label: '経路',
        //   companyLabel: '特記事項',
        // },
      ],
      items: [
        {
          name: 'zip',
          type: 'text',
          label: '郵便番号',
          value: null,
          required: true,
          placeholder: '1020074',
          comment: '半角・ハイフンなしで入力してください',
        },
        {
          name: 'pref',
          type: 'select',
          label: '都道府県',
          value: null,
          options: [],
          required: true,
          comment: '',
        },
        {
          name: 'city',
          type: 'text',
          label: '市区町村',
          value: null,
          required: true,
          placeholder: '千代田区九段南',
        },
        {
          name: 'address',
          type: 'text',
          label: '番地',
          value: null,
          required: true,
          placeholder: '1-5-6',
        },
        {
          name: 'building',
          type: 'text',
          label: '建物名・部屋番号',
          placeholder: 'りそな九段ビル5階 KSフロア',
          value: null,
        },
      ],
      address: null,
      flag: {
        loader: true,
        readySubmit: true,
        changeFile: false,
      },
      file: null,
      previewUrl: null,
      validate: {
        size: false,
        view: true,
      },
    };
  },
  created() {
    if (this.user.email) {
      this.setUserValues();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'user/setUserData') this.setUserValues();
      });
    }
    if (this.helper.master.prefs) {
      this.items[1].options = this.helper.master.prefs;
    } else {
      this.$store.subscribe((mutations) => {
        if (mutations.type === 'helper/putMaster') {
          this.items[1].options = mutations.payload.prefs;
        }
      });
    }
  },
  computed: {
    ...mapState(['user', 'page', 'helper']),
    showVCID() {
      return this.user && this.user.virtualCastProp && this.user.virtualCastProp.virtual_cast_id;
    },
  },
  methods: {
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },
    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },
    /** 子から値を受け取る */
    receiveValue(values) {
      this.flag.readySubmit = values.readySubmit;
      this.address = values.address;
    },
    setUserValues() {
      if (this.user.lti) {
        // LTI利用者は更新ボタン不要のため非表示
        this.membership[1].hideEdit = true;
        // 同じくパスワード変更不要のため削除
        this.membership.pop();
      }
      this.userItems.forEach((item) => {
        item.value = this.user[item.name];
      });
      if (this.user.account_type === 1) {
        this.studentItems.forEach((item) => {
          if (this.user.student) item.value = this.user.student[item.name] || null;
        });
      } else if (this.user.account_type === 3) {
        this.teacherItems.forEach((item) => {
          if (this.user.teacher) item.value = this.user.teacher[item.name];
        });
      }
      if (this.user.address) this.address = this.user.address;
    },
    updateUser(name) {
      this.showLoading();
      let invalid = false;

      // 必須項目で未入力項目があったらinvalid=true
      if (name === 'base') {
        this.userItems.forEach((row) => {
          if (row.required) {
            if (!row.value || row.value === '') invalid = true;
          }
        });
      }

      if (invalid || !this.flag.readySubmit) {
        alert('必須項目が未入力です。');
        this.hideLoading();
        return;
      }

      const data = {
        id: this.user.id,
        account_type: this.user.account_type,
      };

      if (name === 'base') {
        this.userItems.forEach((row) => {
          if (row.edit) data[row.name] = row.value;
        });
      } else if (name === 'additional') {
        data.additionalData = { id: this.user.student.id };
        data.isOnlyAdditional = true;
        this.studentItems.forEach((row) => {
          data.additionalData[row.name] = row.value;
        });
      }

      this.axios({
        method: 'POST',
        url: '/v1/user/set/update',
        data,
      })
        .then(() => {
          if (name === 'base') this.updateAddress();
          else if (name === 'additional') {
            this.$store.dispatch('user/update');
            alert('更新が完了しました。');
            this.hideLoading();
          }
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
          alert('更新に失敗しました。');
          this.hideLoading();
        });
    },
    updateAddress() {
      // 住所
      const data = this.address;
      data.id = this.user.address ? this.user.address.id : null;
      data.model_type = 1;

      const endpoint = data.id ? 'updater' : 'register';

      if (!data.id) {
        data.model_type = 1;
        data.foreign_id = this.user.id;
      }

      this.axios({
        method: 'POST',
        url: `/v1/address/set/${endpoint}`,
        data,
      })
        .then(() => {
          if (this.file) this.uploadFile();
          else if (this.flag.changeFile) this.updateUrl();
          else {
            this.$store.dispatch('user/update');
            alert('更新が完了しました。');
            this.hideLoading();
          }
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
          this.hideLoading();
        });
    },

    /** 画像の更新 */
    updateFile(e) {
      const files = e.target.files || e.dataTransfer.files;

      // files.lengthが0の場合はキャンセル時
      if (!files.length) {
        return;
      }

      // ファイルのサイズチェック（上限2mb）
      const limit = 1024 * 1024 * 2;
      if (files[0].size > limit) {
        this.validate.size = true;
        // ファイルのリセットは非表示→表示で生成しなおす
        this.validate.view = false;
        this.$nextTick(() => {
          this.validate.view = true;
        });
        return;
      }

      // ファイルを格納
      this.file = files[0];
      this.validate.size = false;

      // プレビュー表示
      this.previewIcon();
    },

    previewIcon() {
      const path = window.URL.createObjectURL(this.file);
      this.previewUrl = path;
    },

    deleteFile() {
      this.flag.changeFile = true;
      this.file = null;
      this.previewUrl = '/img/default/noimage.jpg';
    },

    /** ファイルのアップロード */
    uploadFile() {
      if (!this.file) this.updateUrl();
      // 保存時formDataに落とし込む
      const form = new FormData();
      form.append('file', this.file);

      this.axios({
        method: 'POST',
        url: '/v1/user/set/uploadfile',
        data: form,
        params: {
          id: this.user.id,
          environment: this.helper.env.name,
        },
      })
        .then((response) => {
          // URLの紐付けを保存
          this.updateUrl(response.data.data);
        })
        .catch((error) => {
          alert('プロフィール画像のアップロードに失敗しました。');
          if (error.response) console.log(error.response.data);
          else console.log(error);
          this.hideLoading();
        });
    },

    /** URLの紐付け更新 */
    updateUrl(uploadData) {
      // 既存の紐付けがあるか新規登録か
      const id = this.user.urls.icon.length
        ? this.user.urls.icon[0].id
        : null;

      const data = {
        id,
        user_id: this.user.id,
        url: this.file ? uploadData.uploaded.s3Path : null,
        type: 2, // アイコンは2
      };

      this.axios({
        method: 'POST',
        url: '/v1/user/set/updateUrl',
        data,
      })
        .then(() => {
          // 初期化
          this.file = null;
          this.previewUrl = null;
          alert('更新が完了しました。');
          this.$store.dispatch('user/update');
          this.hideLoading();
        })
        .catch((error) => {
          alert('プロフィール画像の更新に失敗しました。');
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },
  },
};
</script>


<style lang="scss" module>
.label {
  width: 200px;
}
.item {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  a {
    color: var(--black);
    &:hover {
      text-decoration: underline;
    }
  }
}
.hideSp {
  @include xs-view {
    display: none;
  }
}
.row {
  padding: 24px 0;
  border-top: 1px solid var(--gray-main);
  &.child {
    border-top: none;
    &:not(:first-child) {
      padding-top: 0;
    }
  }
}
.label_form {
  border-radius: 4px;
  margin-right: 4px;
  font-weight: bold;
  display: inline-block;
  &_wrap {
    display: flex;
    align-items: baseline;
  }
}
.text,
.select {
  &:disabled {
    color: black;
    background-color: transparent;
    &:-webkit-autofill {
      box-shadow: 0 0 0 100px white inset;
    }
  }
}
.text,
.plane {
  width: 100%;
  padding: 13px;
  border-radius: 8px;
  background-color: var(--gray-sub);
  border: none;
  outline: none;
  $background: var(--gray-sub);
  &:-webkit-autofill {
    box-shadow: 0 0 0 100px $background inset;
  }
  &.plane {
    font-size: 14px;
    background-color: transparent;
  }
}
.textarea {
  width: 100%;
  padding: 13px;
  border-radius: 8px;
  background-color: var(--gray-sub);
  border: none;
  outline: none;
  $background: var(--gray-sub);
  &:-webkit-autofill {
    box-shadow: 0 0 0 100px $background inset;
  }
}
.required {
  color: var(--pink);
  font-size: 10px;
  font-weight: normal;
  margin-left: 4px;
}

.detail {
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include sm-view {
    display: block;
    margin-top: 0;
    .editBtn {
      margin-top: 8px;
      color: var(--orange-main);
    }
  }
  .editBtn {
    display: inline-block;
    font-size: 12px;
    padding: 4px 16px;
    border-radius: 16px;
    border: 1px solid var(--orange-main);
    font-weight: bold;
    color: var(--orange-main);

    @include sm-view {
      width: 100%;
      margin: 0 auto;
      margin-top: 8px;
      display: block;
      text-align: center;
    }
    a {
      color: var(--orange-main);
    }
  }
}

.thumbnail {
  width: 100px;
  padding-top: 100px;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  margin-right: 20px;
  border: 1px solid rgba(#aaa, 0.3);
  &_wrap {
    margin-top: 10px;
    display: flex;
    align-items: center;
    input {
      display: none;
    }
  }
  &_btn {
    display: inline-block;
    border-radius: 22px;
    padding: 6px 15px;
    font-size: 14px;
    border: 1px solid var(--black);
  }
}

.assistant {
  font-size: 12px;
  margin-top: 20px;
}
.assistant_lessmargin {
  font-size: 12px;
}
</style>
