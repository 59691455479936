<template>
  <div>
    <spacer :y="5"/>
    <contents-box title="利用者情報入力" id="confirm">
      <div :class="$style.wrapper">
        <div :class="$style.row">
          <div :class="$style.label_wrap">
            <p :class="$style.label">学校・企業選択</p>
            <p :class="$style.required">※必須項目</p>
          </div>
          <!-- セレクト -->
          <div :class="$style.detail">
            <select
              :class="$style.select"
              name="school"
              id="school"
              :disabled="flag.isConfirm"
              v-on:change="updateSchool">
              <option :value="0">選択してください</option>
              <option
                v-for="n of schools"
                :key="n"
                :value="n.id">{{ n.name }}</option>
            </select>
          </div>
        </div>
      </div>
      <div :class="$style.wrapper">
        <!-- 利用者タイプを選択 -->
        <!-- 選択内容によって入力項目変化 -->
        <div :class="$style.row">
          <div :class="$style.label_wrap">
            <p :class="$style.label">利用者種別</p>
            <p :class="$style.required">※必須項目</p>
          </div>
          <div :class="$style.detail">
            <ul :class="$style.radio" v-if="isCompany">
              <li v-for="copt in companyAccountType" :key="copt">
                <input
                  v-if="!flag.isConfirm"
                  type="radio"
                  name="account_type"
                  :id="copt.name"
                  :disabled="flag.isConfirm"
                  :checked="copt.value === userType"
                  v-on:change="updateType">
                <label :for="copt.name"
                  v-if="!flag.isConfirm || copt.value === userType">{{ copt.label }}</label>
              </li>
            </ul>
            <ul :class="$style.radio" v-else>
              <li v-for="opt in accountType" :key="opt">
                <input
                  v-if="!flag.isConfirm"
                  type="radio"
                  name="account_type"
                  :id="opt.name"
                  :disabled="flag.isConfirm"
                  :checked="opt.value === userType"
                  v-on:change="updateType">
                <label :for="opt.name"
                  v-if="!flag.isConfirm || opt.value === userType">{{ opt.label }}</label>
              </li>
            </ul>
          </div>
        </div>

        <!-- 選択内容によって入力項目変化 -->
        <div :class="$style.row" v-if="!isCompany && userType === 1">
          <div :class="$style.label_wrap">
            <p :class="$style.label">利用者学年</p>
            <p :class="$style.required">※必須項目</p>
          </div>
          <div :class="$style.detail">
            <select
              :class="$style.select"
              v-model="schoolYear"
              name="school_year"
              id="school_year"
              :disabled="flag.isConfirm">
              <option :value="null">選択なし</option>
              <option
                :class="$style.centering"
                v-for="n of setSchoolYear()"
                :key="n"
                :value="n">{{ n }}</option>
            </select>
            <span v-if="schoolYear !== null">年生</span>
          </div>
        </div>

        <!-- 全タイプ必須の情報 -->
        <div :class="$style.row" v-for="row in contents" :key="row">
          <div :class="$style.label_wrap">
            <p :class="$style.label">{{ row.label }}</p>
            <p v-if="row.required" :class="$style.required">※必須項目</p>
          </div>


          <!-- テキスト -->
          <div :class="$style.detail" v-if="row.type === 'text'">
            <div>
              <input
                v-if="row.name != 'kana'"
                :class="$style.text"
                :name="row.name"
                :disabled="flag.isConfirm"
                :placeholder="row.placeholder"
                v-model="row.value"
                type="text">
              <input
                v-if="row.name === 'kana'"
                :class="$style.text"
                :name="row.name"
                :disabled="flag.isConfirm"
                :placeholder="row.placeholder"
                v-model.trim="$v.kana.$model"
                v-on:input="invalidsCheck('kana', $v.kana.$invalid)"
                type="text">
            </div>
            <p :class="$style.assistant" v-if="!flag.isConfirm">{{ row.assistant }}</p>
            <p v-if="row.name === 'kana'&& $v.kana.$dirty && $v.kana.custom.$invalid" :class="$style.error">カナ文字のみ入力してください</p>
          </div>

          <!-- ラジオボタン -->
          <div :class="$style.detail" v-if="row.type === 'radio'">
            <ul :class="$style.radio">
              <li v-for="opt in row.options" :key="opt">
                <input
                  type="radio"
                  v-if="!flag.isConfirm"
                  :name="row.name"
                  :id="opt.name"
                  :disabled="flag.isConfirm"
                  v-on:change="updateValue">
                <label
                  :for="opt.name"
                  v-if="!flag.isConfirm || opt.value === userType">{{ opt.label }}</label>
              </li>
            </ul>
          </div>

          <!-- 生年月日 -->
          <div :class="$style.detail" v-if="row.type === 'birthday'">
            <ul :class="$style.select_wrap">
              <li>
                <select
                  :class="$style.select"
                  name="birthday"
                  id="year"
                  :disabled="flag.isConfirm"
                  v-on:change="updateValue">
                  <option
                    :value="0"></option>
                  <option
                    v-for="n of 70"
                    :key="n"
                    :value="optionYear(n)">{{ optionYear(n) }}</option>
                </select>年
              </li>
              <li>
                <select
                  :class="$style.select"
                  name="birthday"
                  id="month"
                  :disabled="flag.isConfirm"
                  v-on:change="updateValue">
                  <option
                    :value="0"></option>
                  <option
                    v-for="n of 12"
                    :key="n"
                    :value="n">{{ n }}</option>
                </select>月
              </li>
              <li>
                <select
                  :class="$style.select"
                  name="birthday"
                  id="day"
                  :disabled="flag.isConfirm"
                  v-on:change="updateValue">
                  <option
                    :value="0"></option>
                  <option
                    v-for="n of 31"
                    :key="n"
                    :value="n">{{ n }}</option>
                </select>日
              </li>
            </ul>
          </div>
        </div>

        <div :class="$style.row">
          <div :class="$style.label_wrap">
            <p :class="$style.label">住所</p>
            <p :class="$style.required">※必須項目</p>
          </div>
          <div :class="$style.detail">
            <spacer :y="2"/>
            <Address
              :items="items"
              :isConfirm="flag.isConfirm"
              v-on:sendValue="receiveValue"/>
          </div>
        </div>

        <div :class="$style.row">
          <div :class="$style.label_wrap">
            <p :class="$style.label">メールアドレス</p>
            <p :class="$style.required">※必須項目</p>
          </div>
          <div :class="$style.detail">
            <div>
              <input
                :class="$style.text"
                :disabled="flag.isConfirm"
                placeholder="メールアドレス"
                v-model.trim="$v.mail.$model"
                id="mail"
                type="email"
                name="mail"
                v-on:input="invalidsCheck('mail', $v.mail.$invalid)">
              <p
                :class="$style.error"
                v-if="$v.mail.$dirty
                  && $v.mail.required.$invalid">メールアドレスを入力してください</p>
              <p
                :class="$style.error"
                v-if="$v.mail.$dirty
                  && $v.mail.email.$invalid">正しいメールアドレスの形式で入力してください</p>
            </div>
          </div>
        </div>

        <div :class="$style.row">
          <div :class="$style.label_wrap">
            <p :class="$style.label">パスワード</p>
            <p :class="$style.required">※必須項目</p>
          </div>
          <div :class="$style.detail">
            <div>
              <input
                :class="$style.text"
                placeholder="パスワード"
                :disabled="flag.isConfirm"
                type="text"
                id="password"
                name="password"
                v-model.trim="$v.password.$model"
                v-on:input="invalidsCheck('password', $v.password.$invalid)">
              <p
                :class="$style.error"
                v-if="$v.password.$dirty
                  && $v.password.minLength.$invalid">パスワードは8文字以上で設定してください</p>
            </div>
            <p :class="$style.assistant" v-if="!flag.isConfirm">半角英数字8桁以上15桁以下</p>
          </div>
        </div>

        <div :class="$style.row">
          <div :class="$style.label_wrap">
            <p :class="$style.label">携帯番号</p>
          </div>
          <div :class="$style.detail">
            <div>
              <input
                :class="$style.text"
                type="text"
                v-model="tel"
                :disabled="flag.isConfirm">
            </div>
            <p :class="$style.assistant" v-if="!flag.isConfirm">半角・ハイフンなしで入力してください</p>
          </div>
        </div>
      </div>

      <div :class="$style.wrapper"
        v-if="userType === 1">
        <div :class="$style.row"
          v-for="row in studentItem"
          :key="row">
          <div :class="$style.label_wrap">
            <p :class="$style.label">{{ isCompany && row.companyLabel ? row.companyLabel : row.label }}</p>
            <p v-if="row.required" :class="$style.required">※必須項目</p>
          </div>
          <!-- セレクト -->
          <div :class="$style.detail" v-if="row.type === 'select'">
            <select
              :class="$style.select"
              :name="row.name"
              :id="row.name"
              :disabled="flag.isConfirm"
              v-on:change="updateSchool">
              <option :value="0" :selected="row.value === 0">選択してください</option>
              <option
                v-for="n of row.options"
                :key="n"
                :value="n.id"
                :selected="n.id === row.value">{{ n.name }}</option>
            </select>
          </div>
          <!-- テキスト -->
          <div :class="$style.detail" v-if="row.type === 'text'">
            <div>
              <input
                :class="$style.text"
                :name="row.name"
                :disabled="flag.isConfirm"
                :placeholder="row.placeholder"
                v-model="row.value"
                type="text">
            </div>
            <p :class="$style.assistant" v-if="!flag.isConfirm">{{ row.assistant }}</p>
          </div>
        </div>
      </div>

      <div :class="$style.wrapper"
        v-if="userType === 2">
        <div :class="$style.row"
          v-for="row in parentItem"
          :key="row">
          <div :class="$style.label_wrap">
            <p :class="$style.label">{{ isCompany && row.companyLabel ? row.companyLabel : row.label }}</p>
            <p v-if="row.required" :class="$style.required">※必須項目</p>
          </div>
          <!-- セレクト -->
          <div :class="$style.detail" v-if="row.type === 'select'">
            <select
              :class="$style.select"
              :name="row.name"
              :id="row.name"
              :disabled="flag.isConfirm"
              v-on:change="updateSchool">
              <option :value="0" :selected="row.value === 0">選択してください</option>
              <option
                v-for="n of row.options"
                :key="n"
                :value="n.id"
                :selected="n.id === row.value">{{ n.name }}</option>
            </select>
          </div>
          <!-- テキスト -->
          <div :class="$style.detail" v-if="row.type === 'text'">
            <div>
              <input
                :class="$style.text"
                :name="row.name"
                :disabled="flag.isConfirm"
                :placeholder="row.placeholder"
                v-model="row.value"
                type="text">
            </div>
            <p :class="$style.assistant" v-if="!flag.isConfirm">{{ row.assistant }}</p>
          </div>
        </div>
      </div>

      <div :class="$style.wrapper"
        v-if="userType === 3">
        <div :class="$style.row"
          v-for="row in teacherItem"
          :key="row">
          <div :class="$style.label_wrap">
            <p :class="$style.label">{{ isCompany && row.companyLabel ? row.companyLabel : row.label }}</p>
            <p v-if="row.required" :class="$style.required">※必須項目</p>
          </div>
          <!-- セレクト -->
          <div :class="$style.detail" v-if="row.type === 'select'">
            <select
              :class="$style.select"
              :name="row.name"
              :id="row.name"
              :disabled="flag.isConfirm"
              v-on:change="updateSchool">
              <option :value="0" :selected="row.value === 0">選択してください</option>
              <option
                v-for="n of row.options"
                :key="n"
                :value="n.id"
                :selected="n.id === row.value">{{ n.name }}</option>
            </select>
          </div>
          <!-- テキスト -->
          <div :class="$style.detail" v-if="row.type === 'text'">
            <div>
              <input
                :class="$style.text"
                :name="row.name"
                :disabled="flag.isConfirm"
                :placeholder="row.placeholder"
                v-model="row.value"
                type="text">
            </div>
            <p :class="$style.assistant" v-if="!flag.isConfirm">{{ row.assistant }}</p>
          </div>
        </div>
      </div>


      <spacer :y="5"/>
      <btn-container>
        <basic-btn
          tag="button"
          v-if="!flag.isConfirm"
          v-on:click="changeFlag">入力内容を確認する</basic-btn>
        <basic-btn
          tag="button"
          v-if="flag.isConfirm"
          v-on:click="changeFlag">編集画面に戻る</basic-btn>
        <spacer :x="2"/>
        <basic-btn
          tag="button"
          v-if="flag.isConfirm"
          v-on:click="regist">入力内容を確定する</basic-btn>
      </btn-container>
    </contents-box>
    <spacer :y="8.5"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { email, required, minLength } from '@vuelidate/validators';
import BasicBtn from '@/views/components/BasicBtn.vue';
import BtnContainer from '@/views/components/BtnContainer.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import Spacer from '@/views/components/Spacer.vue';
import { Address } from '@/views/components/parts/Form';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'operator-regist-user',
  mixins: [cf],
  components: {
    BasicBtn,
    BtnContainer,
    ContentsBox,
    Spacer,
    Address,
  },
  setup() {
    const mail = ref('');
    const password = ref('');
    const kana = ref('');

    // カタカナ・半角スペース・全角スペースのみ許す
    const kanaTextRule = (value) => /^[ァ-ヶー\s　]+$/u.test(value);

    const rules = {
      // メールは必須・email形式
      mail: { required, email },
      // パスワードは必須
      password: {
        required,
        minLength: minLength(8),
      },
      kana: {
        required,
        custom: kanaTextRule,
      },
    };

    const $v = useVuelidate(rules, { mail, password, kana });

    return {
      mail, password, kana, $v,
    };
  },
  data() {
    return {
      flag: {
        readySubmit: false,
        isConfirm: false,
      },
      validationFlags: {
        mail: false,
        password: false,
        kana: false,
      },
      invalids: {
        mail: true,
        password: true,
        minLength: true,
        kana: true,
      },
      schools: [],
      selectedSchoolId: null,
      tel: null,
      address: {},
      additional: ['school', 'school_year', 'student_number'],
      userType: 1,
      accountType: [
        {
          name: 'student',
          label: '生徒',
          value: 1,
        },
        {
          name: 'parent',
          label: '保護者',
          value: 2,
        },
        {
          name: 'teacher',
          label: '教職員',
          value: 3,
        },
      ],
      companyAccountType: [
        {
          name: 'student',
          label: 'プログラム参加者',
          value: 1,
        },
        {
          name: 'teacher',
          label: '企業担当者',
          value: 3,
        },
      ],
      schoolYear: null,
      contents: [
        {
          type: 'text',
          name: 'username',
          label: '名前',
          required: true,
          placeholder: '例：三上　春斗',
          value: '',
        },
        {
          type: 'text',
          name: 'kana',
          label: 'フリガナ',
          required: true,
          placeholder: '例：ミカミ　ハルト',
        },
        {
          type: 'radio',
          name: 'gender',
          label: '性別',
          required: true,
          value: null,
          options: [
            {
              name: 'male',
              label: '男性',
              value: '1',
            },
            {
              name: 'female',
              label: '女性',
              value: 2,
            },
          ],
        },
        {
          type: 'birthday',
          name: 'birthday',
          label: '生年月日',
          required: true,
          values: {
            year: null,
            month: null,
            day: null,
          },
        },
      ],
      studentItem: [
        // {
        //   type: 'text',
        //   name: 'student_id',
        //   label: '学籍番号',
        //   companyLabel: '特記事項',
        //   value: '',
        // },
        // {
        //   type: 'text',
        //   name: 'school_year',
        //   label: '学年',
        //   assistant: '※利用者登録時',
        //   value: '',
        // },
      ],
      parentItem: [
        // {
        //   type: 'text',
        //   name: 'student_id',
        //   label: '学籍番号',
        //   companyLabel: '特記事項',
        //   value: null,
        // },
      ],
      teacherItem: [
        // {
        //   name: 'route',
        //   type: 'text',
        //   label: '経路',
        //   companyLabel: '特記事項',
        //   value: null,
        // },
      ],
      items: [
        {
          name: 'zip',
          type: 'text',
          label: '郵便番号',
          value: null,
          required: true,
          placeholder: '1020074',
          comment: '半角・ハイフンなしで入力してください',
        },
        {
          name: 'pref',
          type: 'select',
          label: '都道府県',
          value: null,
          options: [],
          required: true,
          comment: '',
        },
        {
          name: 'city',
          type: 'text',
          label: '市区町村',
          value: null,
          required: true,
          placeholder: '千代田区九段南',
        },
        {
          name: 'address',
          type: 'text',
          label: '番地',
          value: null,
          required: false,
          placeholder: '1-5-6',
        },
        {
          name: 'building',
          type: 'text',
          label: '建物名・部屋番号',
          placeholder: 'りそな九段ビル5階 KSフロア',
          value: null,
        },
      ],
    };
  },
  created() {
    if (this.helper.master.prefs) {
      this.items[1].options = this.helper.master.prefs;
    } else {
      this.$store.subscribe((mutations) => {
        if (mutations.type === 'helper/putMaster') {
          this.items[1].options = mutations.payload.prefs;
        }
      });
    }
    this.getSchools();
  },
  computed: {
    ...mapState(['user', 'helper']),
    isCompany() {
      const school = this.selectedSchoolId ? this.schools.filter((row) => row.id === this.selectedSchoolId) : null;
      return Boolean(school && school.length && school[0].is_company);
    },
  },
  methods: {
    optionYear(n) {
      const year = Number(this.formatTimestamp(new Date(), 'YYYY')) + 1 - n;
      return year;
    },
    // masterのデータから該当する学校の学年を取得
    setSchoolYear() {
      if (this.selectedSchoolId) {
        const idx = this.schools.findIndex((elem) => elem.id === this.selectedSchoolId);
        const school = this.schools[idx];
        const yearIndex = Object.keys(this.helper.master.schoolYear).findIndex((elem) => this.helper.master.schoolYear[elem].label === school.school_type);
        const schoolYear = this.helper.master.schoolYear[yearIndex].value;
        return schoolYear; // 登録時は実在する学年のみ
      }
    },
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },
    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },
    getSchools() {
      const params = {
        isUsers: 0,
        isAddress: 0,
      };
      this.axios({
        method: 'GET',
        url: '/v1/school/get/list',
        params,
      })
        .then((response) => {
          const res = response.data.data;
          this.schools = res.data;
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },
    /** 無効フラグの更新 */
    invalidsCheck(name, bool) {
      this.invalids[name] = bool;
    },
    /** 子から値を受け取る */
    receiveValue(values) {
      this.flag.readySubmit = values.readySubmit;
      this.address = values.address;
    },
    updateType(e) {
      this.accountType.forEach((row) => {
        if (e.target.id === row.name) {
          this.userType = row.value;
        }
      });
      if (this.userType !== 1) this.schoolYear = null;
    },
    updateValue(e) {
      // ラジオボタン
      if (e.target.type === 'radio') {
        this.contents.forEach((row) => {
          if (row.name === e.target.name) {
            row.options.forEach((opt) => {
              if (e.target.id === opt.name) {
                row.value = opt.value;
              }
            });
          }
        });
      }
      if (e.target.type === 'select-one') {
        this.contents.forEach((row) => {
          if (row.name === e.target.name) {
            row.values[e.target.id] = e.target.value < 9 ? `0${e.target.value}` : `${e.target.value}`;
          }
        });
      }
    },
    updateSchool(e) {
      this.selectedSchoolId = Number(e.target.value);
      this.userType = 1;
    },
    changeFlag() {
      if (!this.flag.isConfirm) {
        // 必須項目が入力されているか確認
        // 学校・企業選択
        if (!this.selectedSchoolId) {
          alert('必須項目が未入力です。');
          return;
        }
        // 共通項目
        let filledRequired = true;
        this.contents.forEach((row) => {
          if (row.required) {
            if (row.name === 'birthday') {
              Object.keys(row.values).forEach((key) => {
                if (!row.values[key]) filledRequired = false;
              });
            } else if (row.value === null || row.value === '') {
              filledRequired = false;
            }
          }
        });

        // 各タイプごとの確認
        let checkArray = [];
        if (this.userType === 1) checkArray = this.studentItem;
        else if (this.userType === 2) checkArray = this.parentItem;
        else if (this.userType === 3) checkArray = this.teacherItem;
        checkArray.forEach((row) => {
          if (row.required) {
            if (row.value === null || row.value === '' || !row.value) {
              filledRequired = false;
            }
          }
        });

        // 生徒かつ学校の場合は学年の入力チェック
        if (this.userType === 1 && !this.isCompany) {
          if (!this.schoolYear) filledRequired = false;
        }

        if (!filledRequired) {
          alert('必須項目が未入力です。');
          return;
        }
        if (!this.flag.readySubmit) {
          alert('住所を正しく入力してください');
          return;
        }
        if (this.invalids.mail || this.invalids.password) {
          alert('メールアドレスまたはパスワードが正しく入力されていません。');
          return;
        }
        if (this.invalids.kana) {
          alert('フリガナが正しく入力されていません。');
          return;
        }

        // 必須項目が入力されていたら進む
        this.flag.isConfirm = !this.flag.isConfirm;
        const elem = document.getElementById('confirm');
        const position = elem.getBoundingClientRect().top;
        scrollBy(0, position - 77);
      } else {
        this.flag.isConfirm = !this.flag.isConfirm;
      }
    },
    regist() {
      this.showLoading();
      const data = {
        additionalData: {},
      };

      // 共通の項目
      this.contents.forEach((row) => {
        if (row.name === 'birthday') {
          data.birthday = `${row.values.year}-${row.values.month}-${row.values.day}`;
        } else {
          data[row.name] = row.value;
        }
      });
      data.school_year = this.schoolYear;
      data.account_type = this.userType;
      data.email = this.mail;
      data.password = this.password;
      data.kana = this.kana;
      data.tel = this.tel;

      // 住所
      data.addressData = this.address;
      data.addressData.model_type = 1;

      // 各タイプごとの項目(additionalData)
      let setArray = [];
      const schoolId = this.selectedSchoolId;
      if (this.userType === 1) setArray = this.studentItem;
      if (this.userType === 2) setArray = this.parentItem;
      if (this.userType === 3) setArray = this.teacherItem;
      setArray.forEach((row) => {
        data.additionalData[row.name] = row.value;
      });

      // 連関テーブル(organizationData)
      const userOrganization = {
        organization_type: 1, // 利用者は学校に紐づくので
        foreign_id: schoolId,
      };
      data.organizationData = userOrganization;

      this.axios({
        method: 'POST',
        url: '/v1/user/set/register',
        data,
      })
        .then(() => {
          alert('利用者の登録が完了しました。');
          this.hideLoading();
          this.$router.push('/operator/?tab=user');
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
          alert('利用者の登録に失敗しました。再度お試しください。');
          this.hideLoading();
        });
    },
  },
};
</script>

<style lang="scss" module>
.row {
  padding: 24px 0;
  border-top: 1px solid var(--gray-main);
}
.label {
  border-radius: 4px;
  margin-right: 4px;
  font-weight: bold;
  display: inline-block;
  &_wrap {
    display: flex;
    align-items: baseline;
  }
}

.text, .select {
  &:disabled {
    color: black;
    background-color: transparent;
    &:-webkit-autofill {
      box-shadow: 0 0 0 100px white inset;
    }
  }
}
.required {
  font-size: 12px;
  color: var(--pink);
}
.assistant {
  font-size: 12px;
}
.error {
  font-size: 12px;
  color: var(--pink);
}
.space {
  padding-top: 6px;
}
.text {
  width: 100%;
  padding: 13px;
  border-radius: 8px;
  background-color: var(--gray-sub);
  border: none;
  outline: none;
  $background: var(--gray-sub);
  &:-webkit-autofill {
    box-shadow: 0 0 0 100px $background inset;
  }
}
.radio {
  padding-top: 4px;
  display: flex;

  li {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}
.select {
  padding: 13px;
  background-color: var(--gray-sub);
  border: none;
  outline: none;
  border-radius: 8px;
  appearance: none;

  &_wrap {
    display: flex;

    li {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}
.centering {
  text-align: center;
}
.tel {
  width: 70px;
  padding: 13px;
}
</style>
