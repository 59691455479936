<template>
  <div>
    <spacer :y="2"/>
    <div :class="$style.sortBox">
      <div :class="$style.top">
        <div :class="$style.search_wrap">
          <word-search-form
            :class="$style.search_word"
            v-on:sendValue="receiveValue"
            :placeholder="isCompany ? '利用者を検索' : '生徒を検索'" />
            <basic-btn
              tag="button"
              @click="getUsers">検索</basic-btn>
        </div>
      </div>
      <spacer :y="2"/>
      <div :class="$style.bottom">
        <div :class="$style.accountType">
          <ul :class="$style.select">
            <li v-if="isCompany" v-for="citem in companyAccountTypeItems" :key="citem">
              <input
                type="checkbox"
                :id="citem.name"
                :value="citem.value"
                v-on:change="updateType($event, 'companyAccount')">
              <label :for="citem.name">{{ citem.label }}</label>
            </li>
            <li v-else v-for="item in accountTypeItems" :key="item">
              <input
                type="checkbox"
                :id="item.name"
                :value="item.value"
                v-on:change="updateType($event, 'account')">
              <label :for="item.name">{{ item.label }}</label>
            </li>
          </ul>
          <spacer :y="2"/>
          <!-- 非アクティブユーザーの表示 -->
          <ul :class="$style.select">
            <li v-for="item in activeTypeItems" :key="item">
              <input
                type="checkbox"
                :id="item.name"
                :value="item.value"
                v-on:change="updateType($event, 'active')">
              <label :for="item.name">{{ item.label }}</label>
            </li>
          </ul>
          <spacer :y="2"/>
          <ul :class="$style.select">
            <li v-for="item in temporaryTypeItems" :key="item" v-if="user.school[0].billing_type.billing_type === 2">
              <input
                type="checkbox"
                name="temporary"
                :id="item.name"
                :value="item.value"
                @change="updateType($event, 'temporary')">
              <label :for="item.name">{{ item.label }}</label>
            </li>
            <li>
              <input
                type="checkbox"
                name="riskAlert"
                id="riskAlert"
                v-model="riskAlert">
              <label for="riskAlert">リスク案件</label>
            </li>
            <li>
              <input
                type="checkbox"
                name="kanasort"
                id="kanasort"
                v-model="kanasort">
              <label for="kanasort">五十音順</label>
            </li>
          </ul>
        </div>
      </div>
      <spacer :y="2"/>
      <div :class="$style.birth">
        <dl>
          <dt><i class="fa-solid fa-calendar-days"></i>生年月日</dt>
          <dd>
            <div>
              <date-small-picker
                :name="'startBirthday'"
                v-on:sendDate="receiveDate" />
            </div>
              <date-small-picker
                :name="'endBirthday'"
                v-on:sendDate="receiveDate" />
          </dd>
        </dl>
        <spacer :y="2"/>
        <dl>
          <dt><i class="fa-solid fa-calendar-plus"></i>登録年月日</dt>
          <dd>
            <div>
              <date-small-picker
                :name="'startCreatedAt'"
                v-model="startCreatedAt"
                v-on:sendDate="receiveDate, autoEnter()" />
            </div>
              <date-small-picker
                :name="'endCreatedAt'"
                v-model="endCreatedAt"
                v-on:sendDate="receiveDate" />
          </dd>
        </dl>
      </div>

      <div v-if="!isCompany" :class="$style.birth">
        <spacer :y="2" />
        <dl>
          <dt><i class="fa-solid fa-graduation-cap"></i>学年</dt>
          <dd>
            <div>
              <select
                :class="$style.select_box"
                v-model="schoolYear"
                name="schoolYear"
                id="schoolYear"
                @change="updateSchoolYear">
                <option :value="null">選択なし</option>
                <option
                  v-for="n of setSchoolYear()"
                  :key="n"
                  :value="n">{{ n }}</option>
                <option :value="0">登録なし</option>
              </select>
            </div>
          </dd>
        </dl>
      </div>
    </div>

    <spacer :y="4"/>
    <hr id="scroll-top">
    <spacer :y="2"/>
    <ul>
      <loader-simple :flag="flag.loader">
        <div v-show="!flag.loader">
          <div :class="$style.label">
            <p>アカウント一覧</p>
            <p :class="$style.large"
              v-if="!flag.loader">{{ `${totalUser}件` }}</p>
            <p v-if="!isCompany" :class="$style.bulk_edit">
              <basic-btn
                tag="button"
                @click="bulkEdit()">ステータス一括編集</basic-btn>
            </p>
          </div>
          <spacer :y="2" />
          <pagination
            :page="page"
            :pageMax="pageMax"
            :path="'/teacher/'"
            v-if="users.length && pageMax !== 1" />
          <spacer :y="5" v-if="users.length && pageMax !== 1" />
          <stack-item v-for="row in users" :key="row.id">
            <user-box
              v-if="users.length"
              :showId="true"
              :showCocomoniBtn="true"
              :ownUser="user"
              :user="row"
              :link="`/teacher/history/${row.rawId || row.id}`"
              :targetBlank="true"
              saveTab="students"
              :bg="userBoxColor(row)">
              <template v-slot:btn v-if="row.flag !== 998">
                <basic-btn
                  type="bdr"
                  tag="button"
                  size="sm"
                  v-on:click="changeUserStatus(row)">非アクティブ</basic-btn>
                <span v-if="!isCompany && row.account_type === 1" :class="$style.edit_school_year">
                  <basic-btn
                    type="bg"
                    tag="button"
                    size="sm"
                    v-on:click="editSchoolYear(row)">学年編集</basic-btn>
                </span>
              </template>
              <template v-slot:main>
                <spacer :y="2"/>
                <div>
                  <ul :class="$style.items" v-if="!row.temporary">
                    <li v-for="item in items" :key="item">
                      <div :class="$style.item" v-if="item.constant || openIds.includes(row.id)">
                        <i :class="[item.icon, $style.icon, $style[item.name]]" v-if="item.constant || openIds.includes(row.id)"/>
                        <div>
                          <p
                            v-if="item.name === 'birthday' && row[item.name]"
                            :class="$style.text">{{ formatTimestamp(row[item.name], 'YYYY/MM/DD') }}</p>
                          <p
                            v-else-if="item.name === 'student_id' && row.student && row.student.student_id"
                            :class="$style.text">{{ row.student.student_id }}</p>
                          <p
                            v-else-if="item.name === 'created_at' && row[item.name]"
                            :class="$style.text">{{ formatTimestamp(row[item.name], 'YYYY年MM月DD日') }} 登録</p>
                          <p v-else-if="item.name === 'virtualCastProp'" :class="$style.text">
                            <a
                              v-if="row[item.name]"
                              :href="`https://virtualcast.jp/users/${row[item.name].virtual_cast_id}`"
                              target="_blank">
                              {{ `https://virtualcast.jp/users/${row[item.name].virtual_cast_id}` }}
                            </a>
                            <label
                              v-else
                              :class="$style.text"
                            >登録なし</label>
                          </p>
                          <p
                            v-else-if="row[item.name]"
                            :class="$style.text"
                            v-html="row[item.name]"></p>
                          <p
                            v-else
                            :class="$style.text">登録なし</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <ul :class="$style.items" v-if="row.temporary">
                    <li>
                      <div :class="$style.item">
                        <i :class="['fa-regular fa-envelope', $style.icon]"/>
                        <div>
                          <p
                            :class="$style.text"
                            v-html="row.email"></p>
                        </div>
                      </div>
                    </li>
                    <spacer :y="2" />
                    <li v-if="user.school[0].billing_type.billing_type === 2 && row.temporary && openIds.includes(row.id)">
                      <p :class="$style.content"><a :class="$style.link" :href="`${baseURL}/signup2/?customer=${row.id}&school=${user.school[0].id}`" target="_blank">{{ baseURL }}/signup2/?customer={{ row.id }}&school={{ user.school[0].id }}</a></p>
                    </li>
                    <spacer v-if="user.school[0].billing_type.billing_type === 2 && row.temporary && openIds.includes(row.id)" :y="2" />
                    <li v-if="user.school[0].billing_type.billing_type === 2 && row.temporary && openIds.includes(row.id)">
                      <p :class="$style.content">
                        <img :src="`https://api.qrserver.com/v1/create-qr-code//?size=150x150&data=${baseURL}/signup2/?school=${user.school[0].id}%26customer=${row.id}`">
                      </p>
                    </li>
                  </ul>
                  <div v-if="openIds.includes(row.id) && row.flag !== 998">
                    <spacer :y="4"/>
                    <a
                      :class="$style.history"
                      :href="`/teacher/history/${row.id}/`">健康相談履歴</a>
                  </div>
                </div>
                <spacer :y="2"/>
                <div :class="$style.open" v-on:click="changeOpenIds(row.id)">
                  <p>
                    {{ !openIds.includes(row.id) ? '詳細情報表示' : '詳細情報非表示' }}
                    <i :class="`fa-regular fa-angles-${!openIds.includes(row.id) ? 'down' : 'up'}`"/>
                  </p>
                </div>
              </template>
            </user-box>
          </stack-item>
          <spacer :y="4"/>
          <pagination
            :page="page"
            :pageMax="pageMax"
            :path="'/teacher/'"
            v-if="users.length && pageMax !== 1" />
          <p v-if="!users.length && (keyword || (startBirthday && endBirthday) || targetAccountType.length)">お探しの検索条件に該当するアカウントは見つかりませんでした。</p>
        </div>
      </loader-simple>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import StackItem from '@/views/components/StackItem.vue';
import UserBox from '@/views/components/UserBox.vue';
import Spacer from '@/views/components/Spacer.vue';
import BasicBtn from '@/views/components/BasicBtn.vue';
import WordSearchForm from '@/views/components/WordSearchForm.vue';
import Pagination from '@/views/components/Pagination.vue';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import DateSmallPicker from '@/views/components/DateSmallPicker.vue';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'students',
  mixins: [cf],
  components: {
    StackItem,
    UserBox,
    Spacer,
    BasicBtn,
    WordSearchForm,
    Pagination,
    LoaderSimple,
    DateSmallPicker,
  },
  data() {
    return {
      flag: {
        loader: true,
        isSort: false,
        forcePage1: false,
        nowLoading: false,
      },
      openIds: [],
      onlineOpenIds: [],
      users: [],
      keyword: null,
      targetColumn: [],
      schoolYear: null,
      riskAlert: false,
      startBirthday: null, // 文字列で渡す
      endBirthday: null, // 文字列で渡す
      startCreatedAt: null,
      endCreatedAt: null,
      totalUser: 0,
      page: 1,
      pageMax: 1,
      limit: 20,
      kanasort: false,
      activeTypeItems: [ // 順番固定 computed?
        {
          name: 'nonActiveUser',
          label: '非アクティブユーザー含む',
          value: 1,
          checked: false,
        },
      ],
      items: [
        {
          label: '登録年月日',
          name: 'created_at',
          icon: 'fa-solid fa-right-to-bracket',
          constant: true,
        },
        {
          label: 'メール',
          name: 'email',
          icon: 'fa-regular fa-envelope',
          constant: true,
        },
        {
          label: '誕生日',
          name: 'birthday',
          icon: 'fa-regular fa-cake-candles',
          constant: false,
        },
        {
          label: '携帯番号',
          name: 'tel',
          icon: 'fa-solid fa-phone',
          constant: false,
        },
        {
          label: 'VC ID',
          name: 'virtualCastProp',
          icon: 'fa-solid fa-vr-cardboard',
          constant: false,
        },
        // {
        //   label: '学籍番号',
        //   companyLabel: '特記事項',
        //   name: 'student_id',
        //   icon: 'fa-regular fa-id-badge',
        //   constant: false,
        // },
        // {
        //   label: 'ひとこと',
        //   name: 'comment',
        //   icon: 'fa-regular fa-comment-dots',
        //   constant: false,
        // },
      ],
      accountTypeItems: [
        {
          name: 'user',
          label: '在学生',
          value: 1,
          checked: false,
        },
        {
          name: 'parent',
          label: '保護者',
          value: 2,
          checked: false,
        },
        {
          name: 'teacher',
          label: '教職員',
          value: 3,
          checked: false,
        },
      ],
      companyAccountTypeItems: [
        {
          name: 'user',
          label: 'プログラム参加者',
          value: 1,
          checked: false,
        },
        {
          name: 'teacher',
          label: '企業担当者',
          value: 3,
          checked: false,
        },
      ],
      temporaryTypeItems: [
        {
          name: 'temporaryOnly',
          label: '仮登録',
          value: 1,
          checked: false,
        },
        {
          name: 'doneOnly',
          label: '本登録',
          value: 2,
          checked: false,
        },
      ],
    };
  },
  created() {
    if (this.user.email) {
      this.getUsers(0);
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'user/setUserData') {
          this.getUsers(0);
        }
      });
    }
  },
  watch: {
    stateWatch: {
      handler() {
        this.getUsers();
      },
      deep: true,
    },
    $route() {
      this.getUsers();
      this.scroll2Top();
    },
  },
  computed: {
    ...mapState(['user', 'helper']),
    baseURL() {
      return `${location.protocol}//${location.host}`;
    },
    stateWatch() {
      return this.$store.state;
    },
    targetAccountType() {
      let array = [];
      let all = true;
      this.accountTypeItems.forEach((row) => {
        if (row.checked) {
          all = false;
          array.push(row.value);
        }
      });
      if (all) array = [1, 2, 3];
      return array;
    },
    targetCompanyAccountType() {
      let array = [];
      let all = true;
      this.companyAccountTypeItems.forEach((row) => {
        if (row.checked) {
          all = false;
          array.push(row.value);
        }
      });
      if (all) array = [1, 3];
      return array;
    },
    isCompany() {
      return Boolean(this.user && this.user.school && this.user.school[0].is_company);
    },
  },
  methods: {
    // masterのデータから該当する学校の学年を取得
    setSchoolYear() {
      if (this.user.school) {
        const school = this.user.school[0];
        const yearIndex = Object.keys(this.helper.master.schoolYear).findIndex((elem) => this.helper.master.schoolYear[elem].label === school.school_type);
        const schoolYear = this.helper.master.schoolYear[yearIndex].value;
        return schoolYear + 2;
      }
    },
    // 子コンポーネントから受け取ったデータを格納
    receiveValue(data) {
      const userIdRegex = new RegExp(/^[0-9０-９]{4}(-|ー|−|―|‐|-|ｰ)[0-9０-９]{1,}(-|ー|−|―|‐|-|ｰ)[0-9０-９]{4}$/);
      if (userIdRegex.test(data.value)) {
        const userId = data.value.split(/[-|ー|−|―|‐|-|ｰ]/);
        this.keyword = String(Number(this.toHalfNumber(userId[2]))); // user.id指定
        this.targetColumn = ['id', 'old_id', 'tel']; // 0120-12-3456のtelも引っかかる
      } else {
        this.keyword = data.value;
        this.targetColumn = ['username', 'kana', 'email', 'tel', 'id', 'old_id'];
      }
      // this.getUsers();
    },

    bulkEdit() {
      const data = this.user;
      data.maxSchoolyear = this.setSchoolYear();
      const args = {
        modalName: 'bulk-edit-school-year',
        data,
      };
      this.$store.dispatch('modal/contents/showModal', args, { root: true });
    },

    /** ハイライトされたIDを数値化 */
    strintToNumber(data) {
      let num;
      if (typeof data === 'string') num = Number(data.replace('<span class="highlight">', '').replace('</span>', ''));
      else if (typeof data === 'number') num = data;
      return num;
    },

    toHalfNumber(str) {
      return str.replace(/[０-９]/g, (s) => String.fromCharCode(s.charCodeAt(0) - 0xFEE0));
    },

    /** アカウントタイプの絞り込み */
    updateType(e, name) {
      this[`${name}TypeItems`].forEach((row) => {
        if (e.target.id === row.name) row.checked = e.target.checked;
      });
      // this.getUsers();
    },

    // 学年で絞り込み
    updateSchoolYear(e) {
      this.schoolYear = Number(e.target.value);
      if (e.target.value === '選択なし') this.schoolYear = null;
      // this.getUsers();
    },

    getUsers() {
      // ページ情報の取得
      const query = this.$route.query;
      if (query.page) {
        this.page = Number(query.page);
      } else {
        // queryにpageがなければ追加してreplace
        this.$router.replace('/teacher/?tab=students&page=1');
        return;
      }

      // 重複したロードを制御
      if (this.flag.nowLoading) return;
      this.flag.nowLoading = true;
      this.flag.loader = true;

      // console.log(`getUsers: ${this.page}`);
      // console.log('-------------------------------');
      const params = {
        school_id: this.user.school[0].id, // 学校の絞り込みは必須
        orderBy: 'desc',
        school_year: this.schoolYear,
        targetColumn: this.keyword && this.keyword !== '' ? this.targetColumn : null,
        keyword: this.keyword && this.keyword !== '' ? this.keyword : null,
        accountTypes: this.isCompany ? this.targetCompanyAccountType : this.targetAccountType,
        betweenBirthday: [this.startBirthday, this.endBirthday],
        betweenCreatedAt: [this.startCreatedAt, this.endCreatedAt],
        temporaryOnly: this.temporaryTypeItems[0].checked ? 1 : 0,
        noTemporary: this.temporaryTypeItems[1].checked ? 1 : 0,
        page: this.page,
        limit: this.riskAlert ? 99999 : this.limit,
        includeDeleted: this.activeTypeItems[0].checked ? 1 : 0,
        isAddress: 0,
        isSchool: 0,
        riskAlert: this.riskAlert ? 1 : 0,
        isRisk: 1,
      };
      if (this.kanasort) { // 五十音順にチェックがあればあいうえおソート
        params.orderBy = 'asc';
        params.order = 'kana';
      }

      this.axios({
        method: 'GET',
        url: '/v1/user/get/search',
        params,
      })
        .then((response) => {
          const res = response.data;
          res.users.data.forEach((user) => { user.school = this.user.school[0]; });
          const users = res.users.data;
          this.totalUser = res.users.total || 0;
          if (users && users.length && this.keyword) {
            const searchTargets = [...this.targetColumn, 'customer_id'];
            users.forEach((user) => {
              searchTargets.forEach((column) => {
                if (user[column] && String(user[column]).includes(this.keyword)) {
                  if (column === 'id') user.rawId = Number(user[column]);
                  user[column] = String(user[column]).replace(new RegExp(this.keyword, 'g'), `<span class="highlight">${this.keyword}</span>`);
                }
              });
            });
          }
          this.users = users;
          this.pageMax = res.users.lastPage;
          this.flag.loader = false;
          this.checkSortFlagStatus();
          if (this.page > this.pageMax) {
            this.$router.push({ query: { tab: 'students', page: 1 } });
          }
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        })
        .finally(() => {
          this.flag.nowLoading = false;
          this.flag.loader = false;
        });
    },

    // ソートされている状態か確認
    checkSortFlagStatus() {
      if (this.keyword
        || this.startBirthday
        || this.endBirthday
        || this.startCreatedAt
        || this.endCreatedAt
        || this.targetAccountType.length !== 3) {
        this.flag.isSort = true;
      } else {
        this.flag.isSort = false;
      }
    },

    changeOpenIds(n) {
      if (this.openIds.includes(n)) {
        const idx = this.openIds.indexOf(n);
        this.openIds.splice(idx, 1);
      } else {
        this.openIds.push(n);
      }
    },
    changeUserStatus(user) {
      const data = user;
      const id = this.strintToNumber(data.id);
      data.id = id;
      const args = {
        modalName: 'confirm-change-status',
        data,
      };
      this.$store.dispatch('modal/messages/showModal', args, { root: true });
    },
    editSchoolYear(user) {
      const data = user;
      data.maxSchoolyear = this.setSchoolYear();
      const id = this.strintToNumber(data.id);
      data.id = id;
      const args = {
        modalName: 'edit-school-year',
        data,
      };
      this.$store.dispatch('modal/messages/showModal', args, { root: true });
    },
    scroll2Top() {
      // 上までスクロール
      const elem = document.getElementById('scroll-top');
      const position = elem.getBoundingClientRect().top;
      scrollBy(0, position - 77);
    },

    /** 誕生日と登録日の始点と終点取得 */
    receiveDate(args) {
      if (args.date === 'Invalid date') this[args.name] = null;
      else this[args.name] = args.date;
      // this.getUsers();
    },

    /** 登録日の締めを自動で入力 */
    autoEnter() {
      if (this.startCreatedAt && !this.endCreatedAt) {
        this.endCreatedAt = moment(new Date()).format('YYYY-MM-DD');
      }
    },

    /** userBox背景色指定用 */
    userBoxColor(user) {
      let color;
      if (user.flag !== 1) {
        color = 'gray';
      } else if (user.temporary) {
        color = 'silver';
      } else if (user.risk.length && user.risk[user.risk.length - 1].type !== 11) {
        color = 'red';
      } else {
        color = 'white';
      }
      return color;
    },
  },
};
</script>

<style lang="scss" module>
.search {
  &_wrap {
    display: flex;
    width: 100%;
  }
  &_word {
    flex: 1;
    margin-right: 20px;
  }
  &_items {
    display: flex;
    align-items: center;
    select {
      border: none;
      outline: none;
      padding: 13px;
      border-radius: 8px;
      background-color: var(--gray-sub);
      margin-right: 10px;
    }
    .checklabel {
      // font-size: 14px;
      margin-left: 5px;
    }
  }
}

.link {
  color: var(--black);
  text-decoration: underline;
}
.icon {
  color: var(--orange-main);
  margin-right: 8px;
  font-size: 20px;
  display: inline-block;
  width: 20px;
  &.virtualCastProp {
    font-size: 17px;
    color: var(--green-main);
  }
}
.items {
  // font-size: 14px;
  margin-top: -16px;
  .item {
    display: flex;
    align-items: flex-start;
    margin-top: 16px;
    line-height: 20px;
  }
}
.open {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--gray-side);
  font-size: 14px;
  cursor: pointer;

  &:hover {
    opacity: .7;
  }
}
.history {
  font-size: 14px;
  // background-color: var(--orange-main);
  // color: #fff;
  font-weight: bold;
  color: var(--orange-main);
  border: 1px solid var(--orange-main);
  padding: 8px 16px;
  border-radius: 24px;
}
.pagenation {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--gray-side);
  p {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    margin: 0 10px;
    border-radius: 50%;
    border: 1px solid var(--orange-main);
    color: var(--orange-main);
    cursor: pointer;
    &.selected {
      background-color: var(--orange-main);
      color: #fff;
    }
    span {
      cursor: pointer;
    }
  }
  i {
    margin: 0 15px;
    font-size: 30px;
    color: var(--orange-main);
    cursor: pointer;
    &.invalid {
      color: var(--gray-main);
      cursor: initial;
    }
  }
}
.sortBox {
  > * {
    align-items: center;
  }
  @include sm-view {
    > * {
      display: block;
      .search_wrap {
        width: 100%;
        .search_word {
          margin: 0;
        }
      }
    }
  }
}
.birth {
  flex: 1;
  dl {
    display: flex;
    align-items: center;
    margin: 0;
  }
  dt {
    width: 7em;
    i {
      margin-right: .5em;
    }
  }
  dd {
    flex: 1;
    display: flex;
    margin-left: 1em;
    > div {
      display: flex;
      align-items: center;
      &:nth-child(2) {
        margin-left: 1em;
        &:before {
          content: "~";
          margin-right: 1em;
        }
      }
    }
  }
  @include sm-view {
    dl {
      display: block;
      margin: 1em 0 .4em;
    }
    dt {
      font-size: 14px;
    }
    dd {
      margin: 0;
    }
  }
}
.select {
  display: flex;
  > li {
    &:not(:first-child) {
      margin-left: 1em;
    }
  }
  &_box {
    padding: 13px;
    background-color: var(--gray-sub);
    border: none;
    outline: none;
    border-radius: 8px;
    appearance: none;

    &_wrap {
      display: flex;

      li {
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }
}
.edit_school_year {
  padding-left: 1em;
}
.bulk_edit {
  margin-left: auto;
}
.label {
  display: flex;
  font-weight: bold;
  font-size: 20px;
  align-items: center;

  @include sm-view {
    font-size: 16px;
  }
  .large {
    margin-left: 1em;
    font-size: 24px;
    @include sm-view {
      font-size: 18px;
    }
  }
}
</style>
