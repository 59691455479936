<template>
  <div>
    <spacer :y="5"/>
    <contents-box title="利用者情報更新" id="confirm">
      <loader-simple :flag="flag.loader">
        <div v-show="!flag.loader">
          <div :class="$style.wrapper">
            <!-- 利用者タイプを選択 -->
            <!-- 選択内容によって入力項目変化 -->
            <div :class="$style.row">
              <div :class="$style.label_wrap">
                <p :class="$style.label">利用者種別</p>
                <p :class="$style.required">※必須項目</p>
              </div>
              <div :class="$style.detail">
                <ul :class="$style.radio" v-if="isCompany" >
                  <li v-for="copt in companyAccountType" :key="copt">
                    <input
                      type="radio"
                      name="account_type"
                      :id="copt.name"
                      v-if="!flag.isConfirm"
                      :checked="copt.value === userType"
                      v-on:change="updateType">
                    <label :for="copt.name" v-if="copt.value === userType || !flag.isConfirm">{{ copt.label }}</label>
                  </li>
                </ul>
                <ul :class="$style.radio" v-else>
                  <li v-for="opt in accountType" :key="opt">
                    <input
                      type="radio"
                      name="account_type"
                      :id="opt.name"
                      v-if="!flag.isConfirm"
                      :checked="opt.value === userType"
                      v-on:change="updateType">
                    <label :for="opt.name" v-if="opt.value === userType || !flag.isConfirm">{{ opt.label }}</label>
                  </li>
                </ul>
              </div>
            </div>
            <div :class="$style.row" v-if="!isCompany && userType === 1">
              <div :class="$style.label_wrap">
                <div :class="$style.label">利用者学年</div>
                <p :class="$style.required">※必須項目</p>
              </div>
              <div>
                <select
                  :class="$style.select"
                  v-model="schoolYear"
                  name="school_year"
                  id="school_year"
                  :disabled="flag.isConfirm">
                  <option :value="null">選択なし</option>
                  <option
                    :class="$style.centering"
                    v-for="n of maxSelectSchoolYear"
                    :key="n"
                    :value="n">{{ n }}</option>
                </select>
                <span v-if="schoolYear !== null">年生</span>
              </div>
            </div>

            <!-- 全タイプ共通の情報 -->
            <div :class="$style.row" v-for="row in contents" :key="row">
              <div :class="$style.label_wrap">
                <p :class="$style.label">
                  {{ (customer && customer.school[0].is_company) && row.companyLabel ? row.companyLabel : row.label }}
                </p>
                <p v-if="row.required" :class="$style.required">※必須項目</p>
              </div>

              <!-- テキスト -->
              <div :class="$style.detail" v-if="row.type === 'text'">
                <div>
                  <input
                    :class="$style.text"
                    :name="row.name"
                    :disabled="flag.isConfirm"
                    :placeholder="row.placeholder"
                    v-model="row.value"
                    type="text">
                </div>
                <p :class="$style.assistant" v-if="!flag.isConfirm">{{ row.assistant }}</p>
              </div>

              <!-- ラジオボタン -->
              <div :class="$style.detail" v-if="row.type === 'radio'">
                <ul :class="$style.radio">
                  <li v-for="opt in row.options" :key="opt">
                    <input
                      type="radio"
                      :name="row.name"
                      :id="opt.name"
                      v-if="!flag.isConfirm"
                      :disabled="flag.isConfirm"
                      :checked="row.value === opt.value"
                      v-on:change="updateValue">
                    <label :for="opt.name" v-if="opt.value === row.value || !flag.isConfirm">{{ opt.label }}</label>
                  </li>
                </ul>
              </div>

              <!-- セレクト -->
              <div :class="$style.detail" v-if="row.type === 'select'">
                <select
                  :class="$style.select"
                  :name="row.name"
                  :id="row.name"
                  :disabled="flag.isConfirm || row.name === 'school'">
                  <option :value="0" :selected="row.value === 0">選択してください</option>
                  <option
                    v-for="n of row.options"
                    :key="n"
                    :value="n.id"
                    :selected="n.id === row.value">{{ n.name }}</option>
                </select>
              </div>

              <!-- 生年月日 -->
              <div :class="$style.detail" v-if="row.type === 'birthday'">
                <ul :class="$style.select_wrap">
                  <li>
                    <select
                      :class="$style.select"
                      name="birthday"
                      id="year"
                      :disabled="flag.isConfirm"
                      v-on:change="updateValue">
                      <option
                        :value="0"></option>
                      <option
                        v-for="n of 70"
                        :key="n"
                        :selected="row.values.year === optionYear(n)"
                        :value="optionYear(n)">{{ optionYear(n) }}</option>
                    </select>年
                  </li>
                  <li>
                    <select
                      :class="$style.select"
                      name="birthday"
                      id="month"
                      :disabled="flag.isConfirm"
                      v-on:change="updateValue">
                      <option
                        :value="0"></option>
                      <option
                        v-for="n of 12"
                        :key="n"
                        :selected="row.values.month === n"
                        :value="n">{{ n }}</option>
                    </select>月
                  </li>
                  <li>
                    <select
                      :class="$style.select"
                      name="birthday"
                      id="day"
                      :disabled="flag.isConfirm"
                      v-on:change="updateValue">
                      <option
                        :value="0"></option>
                      <option
                        v-for="n of 31"
                        :key="n"
                        :selected="row.values.day === n"
                        :value="n">{{ n }}</option>
                    </select>日
                  </li>
                </ul>
              </div>
            </div>

            <div :class="$style.row">
              <div :class="$style.label_wrap">
                <p :class="$style.label">住所</p>
                <p :class="$style.required">※必須項目</p>
              </div>
              <div :class="$style.detail">
                <spacer :y="2"/>
                <Address
                  :items="items"
                  :isConfirm="flag.isConfirm"
                  v-on:sendValue="receiveValue"
                  ref="address"/>
              </div>
            </div>

            <div :class="$style.row">
              <div :class="$style.label_wrap">
                <p :class="$style.label">携帯番号</p>
              </div>
              <div :class="$style.detail">
                <div>
                  <input
                    :class="$style.text"
                    type="text"
                    v-model="tel"
                    :disabled="flag.isConfirm">
                </div>
                <p :class="$style.assistant" v-if="!flag.isConfirm">半角・ハイフンなしで入力してください</p>
              </div>
            </div>
          </div>

          <div :class="$style.wrapper"
            v-if="userType === 1">
            <div :class="$style.row"
              v-for="row in studentItem"
              :key="row">
              <div :class="$style.label_wrap">
                <p :class="$style.label">{{ isCompany && row.companyLabel ? row.companyLabel : row.label }}</p>
                <p v-if="row.required" :class="$style.required">※必須項目</p>
              </div>
              <!-- テキスト -->
              <div :class="$style.detail" v-if="row.type === 'text'">
                <div>
                  <input
                    :class="$style.text"
                    :name="row.name"
                    :disabled="flag.isConfirm"
                    :placeholder="row.placeholder"
                    v-model="row.value"
                    type="text">
                </div>
                <p :class="$style.assistant" v-if="!flag.isConfirm">{{ row.assistant }}</p>
              </div>
            </div>
          </div>

          <div :class="$style.wrapper"
            v-if="userType === 2">
            <div :class="$style.row"
              v-for="row in parentItem"
              :key="row">
              <div :class="$style.label_wrap">
                <p :class="$style.label">{{ row.label }}</p>
                <p v-if="row.required" :class="$style.required">※必須項目</p>
              </div>
              <!-- テキスト -->
              <div :class="$style.detail" v-if="row.type === 'text'">
                <div>
                  <input
                    :class="$style.text"
                    :name="row.name"
                    :disabled="flag.isConfirm"
                    :placeholder="row.placeholder"
                    v-model="row.value"
                    type="text">
                </div>
                <p :class="$style.assistant" v-if="!flag.isConfirm">{{ row.assistant }}</p>
              </div>
            </div>
          </div>

          <div :class="$style.wrapper"
            v-if="userType === 3">
            <div :class="$style.row"
              v-for="row in teacherItem"
              :key="row">
              <div :class="$style.label_wrap">
                <p :class="$style.label">{{ isCompany && row.companyLabel ? row.companyLabel : row.label }}</p>
                <p v-if="row.required" :class="$style.required">※必須項目</p>
              </div>
              <!-- テキスト -->
              <div :class="$style.detail" v-if="row.type === 'text'">
                <div>
                  <input
                    :class="$style.text"
                    :name="row.name"
                    :disabled="flag.isConfirm"
                    :placeholder="row.placeholder"
                    v-model="row.value"
                    type="text">
                </div>
                <p :class="$style.assistant" v-if="!flag.isConfirm">{{ row.assistant }}</p>
              </div>
            </div>
          </div>

          <!-- バーチャルキャスト関連項目 -->
          <div :class="$style.wrapper">
            <div :class="$style.row"
              v-for="row in virtualCastProps"
              :key="row">
              <div :class="$style.label_wrap">
                <p :class="$style.label">{{ row.label }}</p>
                <p v-if="row.required" :class="$style.required">※必須項目</p>
              </div>
              <!--VCID-->
              <div :class="$style.detail" v-if="row.type === 'vcid'">
                <div
                  :class="[
                    $style.vcid,
                    flag.isConfirm ? null : $style.force_link
                  ]"
                  v-on:click="clickVcidInputButton"
                >
                  <input-codes
                    :key="`update-user-vcid-${row.value}`"
                    :digit="6"
                    :value="row.value"
                    :smallMode="true"
                    :readonly="true"
                  />
                </div>
                <p
                  v-if="row.value"
                  :class="$style.assistant"
                ><a :href="getVirtualCastUserURL(row.value)" target="_blank">{{ getVirtualCastUserURL(row.value) }}</a></p>
                <!-- バーチャルキャストID用ボタン -->
                <div :class="$style.thumbnail_wrap">
                  <label
                    v-if="!flag.isConfirm"
                    :class="$style.thumbnail_btn"
                    v-on:click="clickVcidClearButton">クリア</label>
                  <p :class="$style.assistant" v-if="!flag.isConfirm">{{ row.assistant }}</p>
                </div>
              </div>
            </div>
          </div>

          <btn-container>
            <basic-btn
              tag="button"
              v-if="!flag.isConfirm"
              v-on:click="changeFlag">入力内容を確認する</basic-btn>
            <basic-btn
              tag="button"
              v-if="flag.isConfirm"
              v-on:click="changeFlag">編集画面に戻る</basic-btn>
            <spacer :x="2"/>
            <basic-btn
              tag="button"
              v-if="flag.isConfirm"
              v-on:click="regist">入力内容を確定する</basic-btn>
          </btn-container>
        </div>
      </loader-simple>
    </contents-box>
    <spacer :y="5"/>

    <!-- <contents-box
      title="インポートcsv 利用回数"
      v-if="billing_type === 'account'">
      <limit-counter
        :flag="flag"
        :school="customer.school[0]"
        :isOperator="true"
        :customer="customer" />
    </contents-box> -->
    <spacer :y="8.5"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BasicBtn from '@/views/components/BasicBtn.vue';
import BtnContainer from '@/views/components/BtnContainer.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import InputCodes from '@/views/components/parts/Form/InputCodes.vue';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import Spacer from '@/views/components/Spacer.vue';
import { Address } from '@/views/components/parts/Form';
// import LimitCounter from '@/views/components/LimitCounter4BillingAccount.vue';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'operator-update-user',
  mixins: [cf],
  components: {
    BasicBtn,
    BtnContainer,
    ContentsBox,
    InputCodes,
    LoaderSimple,
    Spacer,
    Address,
    // LimitCounter,
  },
  data() {
    return {
      customer: null,
      customerId: null,
      flag: {
        loader: true,
        readySubmit: false,
        isConfirm: false,
      },
      tel: null,
      school: null,
      address: {},
      additional: ['school', 'school_year', 'student_number'],
      userType: 1,
      accountType: [
        {
          name: 'student',
          label: '生徒',
          value: 1,
        },
        {
          name: 'parent',
          label: '保護者',
          value: 2,
        },
        {
          name: 'teacher',
          label: '教職員',
          value: 3,
        },
      ],
      companyAccountType: [
        {
          name: 'student',
          label: 'プログラム参加者',
          value: 1,
        },
        {
          name: 'teacher',
          label: '企業担当者',
          value: 3,
        },
      ],
      schoolYear: null,
      maxSelectSchoolYear: null,
      contents: [
        {
          type: 'text',
          name: 'username',
          label: '名前',
          required: true,
          placeholder: '例：三上　春斗',
          value: '',
        },
        {
          type: 'text',
          name: 'kana',
          label: 'フリガナ',
          required: true,
          placeholder: '例：ミカミ　ハルト',
          value: '',
        },
        {
          type: 'text',
          name: 'email',
          label: 'メールアドレス',
          required: true,
          placeholder: 'メールアドレス',
          value: '',
        },
        {
          type: 'radio',
          name: 'gender',
          label: '性別',
          required: true,
          value: null,
          options: [
            {
              name: 'male',
              label: '男性',
              value: 1,
            },
            {
              name: 'female',
              label: '女性',
              value: 2,
            },
          ],
        },
        {
          type: 'birthday',
          name: 'birthday',
          label: '生年月日',
          required: true,
          values: {
            year: null,
            month: null,
            day: null,
          },
        },
        {
          name: 'school',
          type: 'select',
          label: '学校名',
          companyLabel: '企業名',
          value: 0,
          required: true,
          options: [],
        },
      ],
      studentItem: [
        // {
        //   type: 'text',
        //   name: 'student_id',
        //   label: '学籍番号',
        //   companyLabel: '特記事項',
        //   value: '',
        // },
        // {
        //   type: 'text',
        //   name: 'school_year',
        //   label: '学年',
        //   assistant: '※利用者登録時',
        //   value: '',
        // },
      ],
      parentItem: [],
      teacherItem: [
        // {
        //   name: 'route',
        //   type: 'text',
        //   label: '経路',
        //   companyLabel: '特記事項',
        // },
      ],
      items: [
        {
          name: 'zip',
          type: 'text',
          label: '郵便番号',
          value: null,
          required: true,
          placeholder: '1020074',
          comment: '半角・ハイフンなしで入力してください',
        },
        {
          name: 'pref',
          type: 'select',
          label: '都道府県',
          value: null,
          options: [],
          required: true,
          comment: '',
        },
        {
          name: 'city',
          type: 'text',
          label: '市区町村',
          value: null,
          required: true,
          placeholder: '千代田区九段南',
        },
        {
          name: 'address',
          type: 'text',
          label: '番地',
          value: null,
          required: false,
          placeholder: '1-5-6',
        },
        {
          name: 'building',
          type: 'text',
          label: '建物名・部屋番号',
          placeholder: 'りそな九段ビル5階 KSフロア',
          value: null,
        },
      ],
      virtualCastProps: [
        {
          type: 'vcid',
          name: 'virtual_cast_id',
          label: 'バーチャルキャストID',
          required: false,
          placeholder: 'バーチャルキャストID',
          value: null,
        },
      ],
      billing_type: 'default',
      updateCountDoctor: null,
      updateCountPsychologist: null,
    };
  },
  created() {
    if (this.helper.master.prefs) {
      this.items[1].options = this.helper.master.prefs;
    } else {
      this.$store.subscribe((mutations) => {
        if (mutations.type === 'helper/putMaster') {
          this.items[1].options = mutations.payload.prefs;
        }
      });
    }
    this.getSchools();
    this.customerId = cf.convert2Num(this.$route.params.slug);
    this.getCustomer();
  },
  computed: {
    ...mapState(['user', 'helper']),
    isCompany() {
      return Boolean(this.customer && this.customer.school && this.customer.school.length && this.customer.school[0].is_company);
    },
    virtualCastIdRow() {
      return this.virtualCastProps.find((row) => row.name === 'virtual_cast_id');
    },
  },
  methods: {
    optionYear(n) {
      const year = Number(this.formatTimestamp(new Date(), 'YYYY')) + 1 - n;
      return year;
    },
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },
    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },
    getCustomer() {
      const params = {
        id: this.customerId,
      };
      this.axios({
        method: 'GET',
        url: '/v1/user/get/detail',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.customer = res.data;
          this.setCustomerData();
        })
        .catch((error) => {
          if (error.message) {
            if (error.message === 'Request failed with status code 404') this.getInactiveCustomer();
            else console.log(error.message);
          } else console.log(error);
        });
    },

    getInactiveCustomer() {
      console.log('run front getAllConditions');
      const params = { id: this.customerId };
      this.axios({
        method: 'GET',
        url: '/v1/user/get/AllConditions',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.customer = res.data;
          this.setCustomerData();
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    setCustomerData() {
      let target = null;
      this.userType = this.customer.account_type;
      if (this.customer.account_type === 1) target = 'student';
      else if (this.customer.account_type === 2) target = 'parent';
      else target = 'teacher';

      this.schoolYear = this.customer.school_year;

      const transTable = this.helper.master.schoolYear;
      const school = this.customer.school[0];
      // 更新時の選択可能な学年最大値を設定 ※そのままでは文字列なので数値に変換
      this.maxSelectSchoolYear = Number(Object.keys(transTable).find((data) => Number(data) === school.school_type)) + 2;

      this.contents.forEach((row) => {
        if (row.name === 'birthday') {
          let day = this.filterDate(this.customer[row.name]);
          day = day.split('.');
          row.values.year = cf.convert2Num(day[0]);
          row.values.month = cf.convert2Num(day[1]);
          row.values.day = cf.convert2Num(day[2]);
        } else if (row.name === 'school') {
          row.value = this.customer[row.name][0].id;
        } else if (row.name === 'username') {
          row.value = this.judgChar(this.customer[row.name]);
        } else {
          row.value = this.customer[row.name];
        }
      });

      this.tel = this.customer.tel;

      this[`${target}Item`].forEach((row) => {
        if (row.name === 'school') {
          row.value = this.customer.school[0].id;
        } else if (this.customer[target]) {
          row.value = this.customer[target][row.name];
        }
      });

      if (this.customer.address) this.$refs.address.setCustomerData(this.customer.address);
      this.flag.loader = false;

      if (this.customer.school[0].billing_type.billing_type === 2) {
        this.billing_type = 'account';
      } else {
        this.billing_type = 'default';
      }

      // バーチャルキャスト関連
      if (this.customer.virtualCastProp && this.customer.virtualCastProp.virtual_cast_id) {
        this.virtualCastIdRow.value = this.customer.virtualCastProp.virtual_cast_id;
      }
    },

    // 名前に特殊文字が入っていた際は変換して表示
    judgChar(name) {
      let result;
      if (name.includes('&#x') && name.includes(';')) { // 特殊文字のコードを変換
        result = name.replace('&#x', '*'); // インデックス取得用マーカー
        result = result.replace(';', '');
        const targetIndex = result.indexOf('*'); // マーカーのインデックスを取得
        result = result.replace('*', ''); // 開始位置を特定したら不要なので削除
        const hexString = result.slice(targetIndex, targetIndex + 4); // 16進数の文字コードを切り出し
        const hexIndex = result.indexOf(hexString);
        result = result.replace(hexString, '');
        const decrypt = String.fromCharCode(parseInt(hexString, 16));
        result = result.slice(0, hexIndex) + decrypt + result.slice(hexIndex, result.length); // 元の位置に戻す
      } else {
        result = name;
      }
      return result;
    },

    getSchools() {
      this.axios({
        method: 'GET',
        url: '/v1/school/get/list',
        params: {
          isUsers: 0,
        },
      })
        .then((response) => {
          const res = response.data.data;
          const schools = res.data;
          const target = this.contents.filter((c) => c.name === 'school');
          target[0].options = res.data;
          this.school = schools.find((elem) => elem.id === target[0].value);
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },
    /** 子から値を受け取る */
    receiveValue(values) {
      this.flag.readySubmit = values.readySubmit;
      this.address = values.address;
    },
    updateType(e) {
      this.accountType.forEach((row) => {
        if (e.target.id === row.name) {
          this.userType = row.value;
        }
      });
      if (this.userType !== 1) this.schoolYear = null;
    },
    updateValue(e) {
      // ラジオボタン
      if (e.target.type === 'radio') {
        this.contents.forEach((row) => {
          if (row.name === e.target.name) {
            row.options.forEach((opt) => {
              if (e.target.id === opt.name) {
                row.value = opt.value;
              }
            });
          }
        });
      }
      if (e.target.type === 'select-one') {
        this.contents.forEach((row) => {
          if (row.name === e.target.name) {
            row.values[e.target.id] = e.target.value < 9 ? `0${e.target.value}` : `${e.target.value}`;
          }
        });
      }
    },
    changeFlag() {
      if (!this.flag.isConfirm) {
        // 必須項目が入力されているか確認
        // 共通項目
        let filledRequired = true;
        this.contents.forEach((row) => {
          if (row.required) {
            if (row.name === 'birthday') {
              Object.keys(row.values).forEach((key) => {
                if (!row.values[key]) filledRequired = false;
              });
            } else if (row.value === null || row.value === '') {
              filledRequired = false;
            }
          }
        });

        // 各タイプごとの確認
        let checkArray = [];
        if (this.userType === 1) checkArray = this.studentItem;
        else if (this.userType === 2) checkArray = this.parentItem;
        else if (this.userType === 3) checkArray = this.teacherItem;
        checkArray.forEach((row) => {
          if (row.required) {
            if (row.value === null || row.value === '' || !row.value) {
              filledRequired = false;
            }
          }
        });

        // 所属組織が学校であれば学年の入力をチェック
        if (this.userType === 1 && this.school && this.school.is_company === 0) {
          if (!this.schoolYear) filledRequired = false;
        }

        if (!filledRequired) {
          alert('必須項目が未入力です。');
          return;
        }
        if (!this.flag.readySubmit) {
          alert('住所を正しく入力してください');
          return;
        }

        // 必須項目が入力されていたら進む
        this.flag.isConfirm = !this.flag.isConfirm;
        const elem = document.getElementById('confirm');
        const position = elem.getBoundingClientRect().top;
        scrollBy(0, position - 77);
      } else {
        this.flag.isConfirm = !this.flag.isConfirm;
      }
    },

    regist() {
      this.showLoading();
      const data = {
        additionalData: { id: null },
      };

      // 共通の項目
      this.contents.forEach((row) => {
        if (row.name === 'birthday') {
          const m = row.values.month < 10 ? `0${row.values.month}` : row.values.month;
          const d = row.values.day < 10 ? `0${row.values.day}` : row.values.day;
          data.birthday = `${row.values.year}-${m}-${d}`;
        } else {
          data[row.name] = row.value;
        }
      });
      data.id = this.customerId;
      data.account_type = this.userType;
      data.school_year = this.schoolYear;
      data.tel = this.tel;
      // 学校は除外
      delete data.school;

      // 各タイプごとの項目(additionalData)
      let setArray = [];
      if (this.userType === 1) {
        setArray = this.studentItem;
        data.additionalData.id = this.customer.student
          ? this.customer.student.id
          : null;
      } else if (this.userType === 2) {
        setArray = this.parentItem;
        data.additionalData.id = this.customer.parent
          ? this.customer.parent.id
          : null;
      } if (this.userType === 3) {
        setArray = this.teacherItem;
        data.additionalData.id = this.customer.teacher
          ? this.customer.teacher.id
          : null;
      }
      setArray.forEach((row) => {
        if (row.name !== 'school') {
          data.additionalData[row.name] = row.value;
        }
      });

      // バーチャルキャスト関連
      if (this.virtualCastIdRow.value) { // vcidの入力がある
        // 登録更新用のデータをセット
        data.virtualCastProp = {
          virtual_cast_id: this.virtualCastIdRow.value,
        };
      } else if (this.customer.virtualCastProp) { // vcidの入力がなく、vc関連の情報を持っている
        // レコード削除用のデータをセット
        data.virtualCastProp = {
          needDelete: true,
        };
      }

      this.axios({
        method: 'POST',
        url: '/v1/user/set/update',
        data,
      })
        .then(() => {
          this.updateAddress();
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
          alert('更新に失敗しました。');
          this.hideLoading();
        });
    },
    updateAddress() {
      // 住所
      const data = this.address;
      data.id = this.customer.address.id;
      data.model_type = 1;

      this.axios({
        method: 'POST',
        url: '/v1/address/set/updater',
        data,
      })
        .then(() => {
          alert('更新が完了しました。');
          this.hideLoading();
          this.flag.isConfirm = false;
          this.$store.state.updatedUser = this.$route.params.slug;
          // 利用者情報更新ページから利用者タブへ遷移した際には直前の検索条件を継承するよう登録
          this.$store.state.beforePage = 'UpdateUser';
          this.$router.push('/operator/?tab=users');
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
          this.hideLoading();
        });
    },

    /** VCID入力モーダルを開く */
    clickVcidInputButton() {
      if (this.flag.isConfirm) {
        return;
      }
      const args = {
        modalName: 'input-vcid',
        data: {
          callback: (vcid) => {
            this.virtualCastIdRow.value = vcid;
          },
        },
      };
      this.$store.dispatch('modal/contents/showModal', args, { root: true });
    },
    /** VCIDクリア */
    clickVcidClearButton() {
      this.virtualCastIdRow.value = null;
    },
  },
};
</script>

<style lang="scss" module>
.row {
  padding: 24px 0;
  border-top: 1px solid var(--gray-main);
}
.label {
  border-radius: 4px;
  margin-right: 4px;
  font-weight: bold;
  display: inline-block;
  &_wrap {
    display: flex;
    align-items: baseline;
    margin-bottom: .2em;
  }
}
.text, .select {
  &:disabled {
    color: black;
    background-color: transparent;
    &:-webkit-autofill {
      box-shadow: 0 0 0 100px white inset;
    }
  }
}
.required {
  font-size: 12px;
  color: var(--pink);
}
.assistant {
  font-size: 12px;
}
.error {
  font-size: 12px;
  color: var(--pink);
}
.space {
  padding-top: 6px;
}
.text {
  width: 100%;
  padding: 13px;
  border-radius: 8px;
  background-color: var(--gray-sub);
  border: none;
  outline: none;
  $background: var(--gray-sub);
  &:-webkit-autofill {
    box-shadow: 0 0 0 100px $background inset;
  }
}
.radio {
  padding-top: 8px;
  display: flex;

  li {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}
.select {
  padding: 13px;
  background-color: var(--gray-sub);
  border: none;
  outline: none;
  border-radius: 8px;
  appearance: none;

  &_wrap {
    display: flex;

    li {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}
.centering {
  text-align: center;
}
.tel {
  width: 70px;
  padding: 13px;
}
.thumbnail {
  width: 100px;
  padding-top: 100px;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  margin-right: 20px;
  border: 1px solid rgba(#aaa, 0.3);
  &_wrap {
    margin-top: 10px;
    display: flex;
    align-items: center;
  }
  &_btn {
    display: inline-block;
    border-radius: 22px;
    padding: 6px 15px;
    font-size: 14px;
    border: 1px solid var(--black);
    &:not(:first-child) {
      margin-left: 1em;
    }
  }
}
.vcid {
  width: 200px;
}
.force_link {
  cursor: pointer;
  * {
    cursor: pointer;
  }
}
</style>
