<template>
  <div>
    <div :class="$style.input_area">
      <div :class="$style.input_area_title">グループ名<span :class="$style.input_area_required">※必須</span></div>
      <spacer :y="2" />
      <input :class="$style.input_area_frame" type="text" v-model="groupData.label" @input="nameChange = true">
    </div>
    <spacer :y="3" />
    <div :class="$style.input_area">
      <div :class="$style.input_area_title">メンバー追加</div>
      <spacer :y="2" />
      <ul v-if="users && users.find((user) => user.check)" :class="$style.flex_elem">
        <li v-for="user in users"
          :key="user">
          <div v-if="user.check"
            :class="$style.added_scheduled">
            <label :for="`${user.username}_confirmCheck`">{{user.username}}
            <i :class="$style.cross_icon" class="fa-regular fa-xmark"></i></label>
            <input
              type="checkbox"
              :class="$style.added_scheduled_checkbox"
              :id="`${user.username}_confirmCheck`"
              :v-model="user.check"
              @input="confirmCheck(user)"
              :checked="user.check" >
          </div>
        </li>
      </ul>
      <spacer v-if="users && users.find((user) => user.check)" :y="1" />
      <input :class="$style.input_area_frame" type="text" v-model="searchedUser" @input="findUsers" placeholder="ユーザー名で検索">
      <spacer :y="2" />
      <input type="checkbox" id="display_all" v-model="displayAll" :checked="displayAll" @change="displayAllUser">
      <label for="display_all">アクティブユーザー{{ users.length }}名　全て表示</label>
      <div v-if="displayAll && displaySchoolYear">
        <spacer :y="1" />
        <p>学年絞り込み</p>
        <select
          :class="$style.select_box"
          name="schoolYear"
          id="schoolYear"
          v-model="selectedSchoolYear"
          v-on:change="filterlingSchoolYear">filterlingSchoolYear
          <option :value="0">選択してください</option>
          <option
            v-for="n in maxSchoolYear" :key="n"
            :value="n">{{ n }}年生</option>
        </select>
        <div v-if="candidates && candidates.length">
          <spacer :y="1" />
          <dvi :class="$style.flex_elem">
            <p>{{ candidates.length }}名</p>
            <template v-if="showCheckAll">
              <spacer :x="1.5" />
              <div>
                <input type="checkbox" id="all_check" @input="allCheck">
                <label for="all_check">一括チェック</label>
              </div>
            </template>
          </dvi>
        </div>
      </div>
      <spacer :y="1" />
      <table v-if="predict && candidates">
        <tbody>
          <tr v-for="(row, rowIndex) in userRows" :key="rowIndex" :class="$style.row_elem">
            <td v-for="(user, colIndex) in row" :key="colIndex" :class="$style.name_elem">
              <template v-if="addedUsers.some((row) => row.email === user.email)">
                <!-- 所属済ユーザ：チェックマークを表示 -->
                <i class="fa-regular fa-check"></i>
                <label>{{ user.username }}</label>
              </template>
              <template v-else>
                <!-- 未所属ユーザ：所属追加のチェックボックスを表示する -->
                <input type="checkbox" :id="`${user.username}_check`" :v-model="user.check" @input="confirmCheck(user)" :checked="user.check">
                <label :for="`${user.username}_check`">{{ user.username }}</label>
              </template>
            </td>
          </tr>
        </tbody>
      </table>
      <p v-if="predict && !candidates">一致する利用者はいませんでした</p>
    </div>
    <spacer :y="4" />
    <p :class="$style.text_center">（{{ registLabel }}後の所属人数：{{ totalCountAfterProcess }}名）</p>
    <spacer :y="0.5" />
    <btn-container>
      <basic-btn
        v-if="registFlag === 0"
        tag="button"
        @click="addUser">登録する</basic-btn>
      <basic-btn
        v-else-if="registFlag === 1"
        tag="button"
        @click="addUser">更新する</basic-btn>
      <spacer :x="2" />
      <basic-btn
        tag="button"
        @click="cancel">キャンセル</basic-btn>
      <spacer :x="2" />
      <basic-btn
        v-if="registFlag === 1 && !hideDelete"
        tag="button"
        addClass="danger"
        @click="deleteGroup(unit)">グループ削除</basic-btn>
    </btn-container>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BasicBtn from '@/views/components/BasicBtn.vue';
import BtnContainer from '@/views/components/BtnContainer.vue';
import Spacer from '@/views/components/Spacer.vue';

export default {
  name: 'unitseditor',
  components: {
    BasicBtn,
    BtnContainer,
    Spacer,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    school: {
      type: Object,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
    registFlag: {
      type: Number, // 0: 新規登録, 1: 更新
      required: true,
    },
    unit: {
      type: Object,
      default: null,
    },
    hideDelete: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      groupData: {
        label: null,
        name: null,
      },
      searchedUser: null,
      selectedSchoolYear: 0,
      maxSchoolYear: 0,
      candidates: null,
      filtlingDatas: null,
      addUsers: [],
      newAdditions: [],
      nameChange: false,
      predict: false,
      displayAll: false,
      totalCountAfterProcess: 0, // 処理後の所属人数
      checkedAll: false, // 一括チェック
    };
  },
  created() {
    this.setMaxSchoolYear(); // セレクト可能な学年の最大値を学校情報からセット

    if (this.unit) {
      // 更新の時はグループ名、識別名をinput areaに予め入力
      this.groupData.label = this.unit.label;
      this.groupData.name = this.unit.name;
    }

    this.calcTotalCount(); // 初期表示時の所属人数を計算
  },
  watch: {
    candidates() {
      // 表示対象のユーザが変わったタイミングで一括チェックのチェック状態を更新する
      this.checkedAll = this.candidates
        ? this.candidates.every((user) => user.check)
        : false;
    },
  },
  computed: {
    ...mapState(['helper', 'user']),
    /** 一つでも生徒の名前にチェックがついていたらtrueを返す */
    evenChecked() {
      return this.users.some((user) => user.check);
    },

    // ユーザーを5件ずつの配列に分割
    userRows() {
      const rows = [];
      for (let i = 0; i < this.candidates.length; i += 5) {
        rows.push(this.candidates.slice(i, i + 5));
      }
      return rows;
    },

    // 企業アカウントであれば学年の絞り込みを表示しない
    displaySchoolYear() {
      let result = false;
      // 事務局サイト、学校管理者サイトでis_campanyの判定ロジックが少し違う
      if (this.school[0]) { // 学校管理サイト
        if (!(this.school[0].is_company)) result = true;
      } else if (this.school) { // 事務局サイト
        if (!(this.school.is_company)) result = true;
      }
      return result;
    },

    // 所属済みユーザ
    addedUsers() {
      if (!this.unit) {
        return []; // 新規登録時
      }
      return this.unit.users;
    },

    registLabel() {
      return this.registFlag === 0 ? '登録' : '更新';
    },

    showCheckAll() {
      if (!this.candidates || this.candidates.length <= 1) {
        return false;
      }
      // 未所属ユーザーがいるかどうか
      return this.candidates.some((user) => !this.addedUsers.some((row) => row.email === user.email));
    },
  },
  methods: {
    // キャンセル
    cancel() {
      this.$emit('pushCancel', true);
    },

    // グループ情報登録
    registGroup() {
      if (!this.groupData.label) {
        alert('グループ名を入力してください');
        return true;
      }

      const confirmation = confirm(`グループ名 ${this.groupData.label} を登録します。`);
      if (!confirmation) return true;

      let schoolId;
      if (this.school.length) schoolId = this.school[0].id;
      else schoolId = this.school.id;

      const data = {
        school_id: schoolId,
        label: this.groupData.label,
        name: this.groupData.name,
        create_user_id: this.user.id,
        update_user_id: this.user.id,
      };
      this.axios({
        method: 'POST',
        url: '/v1/unit/set/register',
        data,
      })
        .then((response) => {
          if (response.data.exists) {
            alert(`グループ ${this.groupData.label} は既に登録済みです`);
            this.groupData.label = null;
            this.groupData.name = null;
            return true;
          }

          const unitData = response.data;
          this.groupData.name = unitData.data.name;

          if (this.newAdditions.length) {
            this.updateGroup(unitData);
          }

          if (response.data.id) {
            alert('グループの登録が完了しました');
            this.cancel(); // グループ登録欄閉じる
            this.users.forEach((user) => {
              user.check = false;
            });
            this.addUsers = [];
            this.$emit('registGroup', true); // 即時反映のためgrouplistを登録時に親で取得
          }
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    // グループ情報更新
    updateGroup(unit) {
      // const confirmation = confirm(`グループ名${this.groupData.label}を更新します。`);
      // if (!confirmation) return true;
      if (!this.groupData.label) {
        alert('グループ名を入力してください');
        return true;
      }

      let schoolId;
      if (this.school.length) schoolId = this.school[0].id;
      else schoolId = this.school.id;

      const data = {
        school_id: schoolId,
        id: unit.id,
        label: this.groupData.label,
        name: this.groupData.name,
        update_user_id: this.user.id,
        newUsers: this.newAdditions,
      };
      this.axios({
        method: 'POST',
        url: '/v1/unit/set/updater',
        data,
      })
        .then((response) => {
          console.log(response);
          this.cancel(); // 登録エディター閉じる
          this.users.forEach((user) => {
            user.check = false;
          });
          this.addUsers = [];
          this.$emit('registGroup', true);
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    // グループ削除
    deleteGroup() {
      const confirmation = confirm(`${this.unit.label}を削除します。`);
      if (!confirmation) {
        return true;
      }

      let schoolId;
      if (this.school.length) schoolId = this.school[0].id;
      else schoolId = this.school.id;

      const data = {
        school_id: schoolId,
        id: this.unit.id,
        flag: 999,
        update_user_id: this.user.id,
        newUsers: [],
      };
      this.axios({
        method: 'POST',
        url: '/v1/unit/set/updater',
        data,
      })
        .then((response) => {
          console.log(response);
          this.cancel();
          this.$emit('registGroup', true);
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    findUsers() {
      this.candidates = null;
      if (this.searchedUser === '') {
        this.candidates = null;
        this.predict = false;
        return true;
      }

      const target = this.users.filter((user) => user.username.indexOf(this.searchedUser) >= 0);
      this.candidates = target;
      if (this.candidates && this.candidates.length) this.predict = true;
    },

    displayAllUser() {
      if (this.displayAll) {
        this.candidates = this.users;
        this.predict = true;
      } else {
        this.selectedSchoolYear = 0; // 全選択を解除したら選択されている学年をリセット
        this.candidates = null;
        this.predict = false;
      }
    },

    confirmCheck(user) {
      if (!user.check) {
        user.check = true;
      } else {
        user.check = false;
      }
      // 処理後の所属人数を計算
      this.calcTotalCount();
    },

    calcTotalCount() {
      const checkedUsers = this.users.filter((row) => row.check);
      this.totalCountAfterProcess = this.addedUsers.length + checkedUsers.length;
    },

    // グループに追加するユーザーの配列を作成
    addUser() {
      // グループの登録or更新を判定
      if (!this.unit) {
        // 登録時は専用メソッド実行
        this.registUserUnit();
      } else {
        this.addUsers = [];
        const duplicate = [];
        this.users.forEach((user) => {
          this.unit.userList.some((existUser) => {
            let flag = false;
            if (user.email === existUser.user[0].email) {
              if (user.check) {
                duplicate.push(user);
                flag = true;
              }
            }
            return flag;
          });
          if (user.check) {
            this.addUsers.push(user);
          }
        });

        // 重複してるユーザーがいればアラートを出し、未登録ユーザーのみをバックエンドに渡す
        if (duplicate.length) {
          let message = '以下のユーザーは既にこのグループに既に所属しています。';
          duplicate.forEach((user) => { message += `\n${user.username}`; });
          alert(message);

          const newUsers = this.addUsers.filter((user) => {
            let flag = true;
            duplicate.forEach((duplicateUser) => {
              if (user.email === duplicateUser.email) {
                flag = false;
              }
            });
            return flag;
          });
          this.newAdditions = newUsers;
        } else {
          this.newAdditions = this.addUsers;
        }

        this.updateGroup(this.unit);
      }
    },

    // グループ新規登録時
    registUserUnit() {
      this.newAdditions = [];
      this.users.forEach((user) => {
        if (user.check) {
          this.newAdditions.push(user);
        }
      });
      this.registGroup();
    },

    // 学校の最大学年をセット
    setMaxSchoolYear() {
      const accountType = this.user.account_type;
      let maxYear;
      if (accountType >= 30) { // 事務局
        maxYear = this.helper.master.schoolYear[this.school.school_type].value;
      } else if (accountType >= 10 || accountType === 3) { // 学校管理者/教職員
        maxYear = this.helper.master.schoolYear[this.school[0].school_type].value;
      }
      this.maxSchoolYear = maxYear + 2;
    },

    // 学年でフィルタリング
    filterlingSchoolYear() {
      const schoolYearFilter = this.users.filter((elem) => elem.school_year === this.selectedSchoolYear);
      this.candidates = schoolYearFilter;
    },

    // 一括チェック
    allCheck(e) {
      this.checkedAll = e.target.checked;
      this.candidates.forEach((user) => {
        // すでに所属しているユーザーはチェックしない
        if (this.addedUsers.some((row) => row.email === user.email)) {
          return;
        }
        // 未所属ユーザーのみチェック
        user.check = this.checkedAll;
      });
      // 処理後の所属人数を計算
      this.calcTotalCount();
    },
  },
};
</script>

<style lang="scss" module>
.input_area {
  &_title {
    font-weight: bold;
  }
  &_required {
    padding-left: 10px;
    font-weight: lighter;
    font-size: .7em;
    color: red;
  }
  &_frame {
    width: 100%;
    padding: 15px;
    background-color: var(--gray-sub);
    outline: none;
    border: none;
    border-radius: 5px;
  }
}
.flex_elem {
  display: flex;
  flex-wrap: wrap;
}
.added_scheduled {
  border-radius: 5px;
  background-color: var(--gray-sub);
  font-size: .9em;
  padding: 1px;
  margin-right: 5px;
  margin-bottom: 5px;
  &_checkbox {
    display: none;
  }
}
.cross_icon {
  padding: 2px;
  padding-right: 4px;
}
.searched_users {
  padding-right: 15px;
}
.select_box {
  padding: 13px;
  background-color: var(--gray-sub);
  border: none;
  outline: none;
  border-radius: 8px;
  appearance: none;
}
.row_elem {
  vertical-align: text-top;
}
.name_elem {
  width: 200px;
  text-indent: -1em;
  padding-left: 1em;
  word-break: break-all;
  label {
    line-height: .5em;
  }
  i {
    text-indent: 0;
  }
}
.text_center {
  text-align: center;
}
</style>
