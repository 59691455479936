<template>
  <div>
    <ul :class="$style.detail">
      <li :class="$style.justify">
        <label-box
          label="実施中モニタリング"
          color="gray"
          size="sm"
          :class="$style.label"
        />
        <p :class="$style.content">
          <template v-if="schedules.inProgress === null">
            <span>-</span>
          </template>
          <template v-else>
            <span>{{ format(schedules.inProgress.start_time) }}<span :class="$style.dateSeparator">〜</span>{{ format(schedules.inProgress.end_time) }} ({{ getTargetAccountTypeLabel(schedules.inProgress.target_account_types) }})</span>
          </template>
        </p>
      </li>
      <spacer :y="1"/>
      <li :class="$style.justify">
        <label-box
          label="実施予定モニタリング"
          color="gray"
          size="sm"
          :class="$style.label"
        />
        <p :class="$style.content">
          <template v-if="schedules.futures.length === 0">
            <span>-</span>
          </template>
          <template v-else>
            <label
              :class="$style.icon"
              v-on:click="showMonitoringScheduleList('futures')"
            >{{ schedules.futures.length }}件 <i class="fa-solid fa-list" /></label>
            <span>（次回：{{ format(schedules.futures[0].start_time) }}<span :class="$style.dateSeparator">〜</span>{{ format(schedules.futures[0].end_time) }} ({{ getTargetAccountTypeLabel(schedules.futures[0].target_account_types) }})）</span>
          </template>
        </p>
        <div
          :class="$style.icon"
          v-on:click="clickMonitoringScheduleRegister"
        >
          <i class="fa-solid fa-plus"></i>新規追加
        </div>
      </li>
      <spacer :y="1"/>
      <li :class="$style.justify">
        <label-box
          label="実施済モニタリング"
          color="gray"
          size="sm"
          :class="$style.label"
        />
        <p :class="$style.content">
          <template v-if="schedules.pasts.length === 0">
            <span>-</span>
          </template>
          <template v-else>
            <label
              :class="$style.icon"
              v-on:click="showMonitoringScheduleList('pasts')"
            >{{ schedules.pasts.length }}件 <i class="fa-solid fa-list" /></label>
          </template>
          <i />
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import LabelBox from '@/views/components/LabelBox.vue';

import moment from 'moment';
import { cloneDeep } from 'lodash';

const DATETIME_FORMAT = 'YYYY年MM月DD日(ddd) HH:mm';
const SCHEDULES_DEFAULT_DATA = {
  inProgress: null, // 実施中
  futures: [], // 実施予定
  pasts: [], // 実施済
};

export default {
  name: 'monitoring-schedules',
  components: {
    LabelBox,
  },
  props: {
    school: {
      type: Object,
    },
  },
  data() {
    return {
      schedules: null,
    };
  },
  created() {
    // 登録済みのモニタリングスケジュールを取得する
    this.getSchedules();
  },
  computed: {
    ...mapState(['helper']),
  },
  methods: {
    initializeSchedulees() {
      this.schedules = cloneDeep(SCHEDULES_DEFAULT_DATA);
    },
    async getSchedules() {
      this.initializeSchedulees();
      try {
        const response = await this.axios({
          method: 'GET',
          url: '/v1/monitoringSchedule/get/list',
          params: {
            school_id: this.school.id,
            type: 1, // モニタリング
          },
        });
        const schedules = response.data.data;
        // 現在を基準としてカテゴリ分けする
        const now = moment(new Date());
        schedules.forEach((schedule) => {
          const start = moment(schedule.start_time);
          const end = moment(schedule.end_time);
          if (now.isBetween(start, end)) { // 実施中
            this.schedules.inProgress = schedule;
          } else if (now.isBefore(start)) { // 実施予定
            this.schedules.futures.push(schedule);
          } else { // 実施済
            this.schedules.pasts.push(schedule);
          }
        });
        // 実施済のスケジュールは新しい順にする
        this.schedules.pasts.reverse();
      } catch (e) {
        alert('モニタリングスケジュール情報の取得に失敗しました');
        throw e;
      }
    },

    format(datetime) {
      return moment(datetime).format(DATETIME_FORMAT);
    },

    clickMonitoringScheduleRegister() {
      const args = {
        modalName: 'register-monitoring-schedule',
        data: {
          school: this.school,
          isRegister: true,
          callback: this.getSchedules,
        },
      };
      this.$store.dispatch('modal/contents/showModal', args, { root: true });
    },
    showMonitoringScheduleList(target) {
      const args = {
        modalName: 'list-monitoring-schedule',
        data: {
          school: this.school,
          monitoringSchedules: this.schedules[target],
          editable: target === 'futures', // 未来のスケジュールのみ更新可
          callback: this.getSchedules,
        },
      };
      this.$store.dispatch('modal/contents/showModal', args, { root: true });
    },
    getTargetAccountTypeLabel(targetAccountTypes) {
      const SELECTABLE_ACCOUNT_TYPES = [
        1, // 生徒・プログラム対象者
        3, // 教職員・企業担当者
      ];
      const accountTypes = JSON.parse(targetAccountTypes);
      if (accountTypes.length >= SELECTABLE_ACCOUNT_TYPES.length) {
        return '利用者全員';
      }
      const masterLabel = this.school.is_company ? this.helper.master.labels.users.company_account_type : this.helper.master.labels.users.account_type;
      return accountTypes.map((type) => masterLabel[type]).join('、');
    },
  },
};
</script>

<style lang="scss" module>
.detail {
  li {
    display: flex;
    align-items: flex-start;
    .label {
      width: 150px;
    }
    .content {
      margin-left: 20px;
      margin-top: 3px;
      flex: 1;
      word-break: break-all;
    }
    &:not(:last-child) {
      margin-bottom: .5rem;
    }
  }
  @include sm-view {
    font-size: 14px;
    li {
      display: block;
      .label {
        width: 100%;
        margin: 0 0 .5em;
        text-align: left;
        padding: .5em;
      }
    }
  }
}

.icon {
  cursor: pointer;
  color: var(--gray-side);
  > i {
    font-size: 20px;
    margin-right: .5rem;
  }
}
.dateSeparator {
  margin: 0 .5rem;
}

.justify {
  >div {
    // LabelBoxへのパッチ
    text-align: justify;
    text-align-last: justify;
    padding-left: 6px !important;
    padding-right: 6px !important;

    @include sm-view {
      text-align: inherit;
      text-align-last: inherit;
      padding: inherit;
    }
  }
}
</style>
