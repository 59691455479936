<template>
  <div>

    <h2>モニタリングスケジュール（{{ data.school.name }}）</h2>
    <spacer :y="3"/>
    <div>
      <calendar
        :key="calendar.key"
        :information="true"
        :schedule="calendar.schedule"
        :selectableSunSat="false"
        :selectableHoliday="false"
        columns="2"
        :rows="calendarRows"
        :optionalAttrs="optionalAttrs"
      />
    </div>
    <spacer :y="3"/>

    <div
      v-for="(schedules, key) in categorizedSchedules"
      :key="key"
    >
      <label-box
        :label="data.editable ? `${key}年度` : `${key}年度 実施済モニタリング(${schedules.length}件)`"
        color="gray"
      />
      <spacer :y="1.5"/>
      <ul :class="$style.detail">
        <li
          v-for="schedule in schedules"
          :key="schedule.id"
        >

          <!-- 編集モード -->
          <template v-if="editingSchedule.id === schedule.id">
            <div>
              <div :class="$style.detail_div">
                <date-small-picker
                  size="sm"
                  v-model="editingSchedule.startDate"
                  :minDate="selectableMinDate"
                  :maxDate="selectableMaxDate"
                />
                <select
                  :class="$style.select"
                  v-model="editingSchedule.startTime"
                >
                  <option
                    v-for="hour in SELECTABLE_TIME.HOURS"
                    :key="hour"
                    :value="hour"
                  >{{ hour }}</option>
                </select>
                <label :class="$style.dateSeparator">〜</label>
                <date-small-picker
                  size="sm"
                  v-model="editingSchedule.endDate"
                  :minDate="selectableMinDate"
                  :maxDate="selectableMaxDate"
                />
                <select
                  :class="$style.select"
                  v-model="editingSchedule.endTime"
                >
                  <option
                    v-for="hour in SELECTABLE_TIME.HOURS"
                    :key="hour"
                    :value="hour"
                  >{{ hour }}</option>
                </select>
                <div :class="$style.btnWrapper">
                  <basic-btn
                    tag="button"
                    size="sm"
                    v-on:click="clickEditSubmit(schedule)">更新</basic-btn>
                  <basic-btn
                    tag="button"
                    size="sm"
                    v-on:click="clickEditCancel()">キャンセル</basic-btn>
                </div>
              </div>
              <p
                v-show="validationErrorText"
                :class="$style.alert_text"
              >{{ validationErrorText }}</p>
            </div>
          </template>

          <!-- 参照モード -->
          <template v-else>
            <span
              :class="schedule.id === deleteTargetScheduleId ? $style.fontRed : null"
            >{{ format(schedule.start_time) }}<span :class="$style.dateSeparator">〜</span>{{ format(schedule.end_time) }}（{{ getTargetAccountTypeLabel(schedule.target_account_types) }}）</span>
          </template>

          <span
            v-if="data.editable && editingSchedule.id !== schedule.id && notStarted(schedule)"
            :class="$style.iconsWrapper"
          >
            <label
              v-on:click="clickEdit(schedule)"
            ><i class="fa-solid fa-pen" /></label>
            <label
              v-on:click="clickDelete(schedule)"
            ><i class="fa-solid fa-trash" /></label>
          </span>
        </li>
      </ul>
      <p v-if="schedules.length === 0">実施予定モニタリングスケジュールはありません</p>
    </div>
    <spacer :y="3"/>

    <div :class="$style.close" v-on:click="hideModal"></div>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import Calendar from '@/views/components/DatePicker.vue';
import cf from '@/mixins/commonFunctions.js';
import Spacer from '@/views/components/Spacer.vue';
import BasicBtn from '@/views/components/BasicBtn.vue';
import LabelBox from '@/views/components/LabelBox.vue';
import DateSmallPicker from '@/views/components/DateSmallPicker.vue';

import moment from 'moment';
import { cloneDeep } from 'lodash';

const DATETIME_FORMAT = 'YYYY年MM月DD日(ddd) HH:mm';
const SELECTABLE_TIME = {
  HOURS: (() => {
    const start = 8;
    const end = 22;
    const hours = [];
    for (let i = start; i <= end; i += 1) {
      hours.push(`${i < 10 ? '0' : ''}${i}:00`);
    }
    return hours;
  })(),
  DEFAULT_TIME_START: '08:00',
  DEFAULT_TIME_END: '18:00',
  DATE_FORMAT: 'YYYY-MM-DD',
  TIME_FORMAT: 'HH:mm',
};

export default {
  name: 'list-monitoring-schedule',
  props: ['data'],
  // {
  //   school: Object,
  //   monitoringSchedules: Array, // 表示対象スケジュール群
  //   editable: Boolean,
  //   callback: Function,
  // }
  components: {
    Calendar,
    Spacer,
    LabelBox,
    BasicBtn,
    DateSmallPicker,
  },
  data() {
    return {
      SELECTABLE_TIME,
      calendar: {
        key: null,
        schedule: [],
        initialDate: '', // カレンダー初期選択日
      },
      categorizedSchedules: {},
      editingSchedule: {},
      validationErrorText: null,
      optionalAttrs: [],
      deleteTargetScheduleId: null,
      selectableMinDate: moment().format(SELECTABLE_TIME.DATE_FORMAT), // 現在日
      selectableMaxDate: moment().add(1, 'year').format(SELECTABLE_TIME.DATE_FORMAT), // 現在日から1年後
      calendarRows: 1,
    };
  },
  created() {
    if (this.holiday.list.length) {
      this.drawCalendar();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'holiday/setHoliday') {
          this.drawCalendar();
        }
      });
    }
    // 年度別に情報を分ける
    this.data.monitoringSchedules.forEach((schedule) => {
      const fiscalYear = cf.getFiscalYear(schedule.start_time);
      if (!this.categorizedSchedules[fiscalYear]) {
        this.categorizedSchedules[fiscalYear] = [];
      }
      this.categorizedSchedules[fiscalYear].push(cloneDeep(schedule));
    });
  },
  computed: {
    ...mapState(['user', 'holiday', 'helper']),
  },
  methods: {
    async drawCalendar() {
      // 既存のスケジュールを取得
      try {
        const response = await this.axios({
          method: 'GET',
          url: '/v1/monitoringSchedule/get/list',
          params: {
            school_id: this.data.school.id,
            type: 1, // モニタリング
          },
        });
        const monitoringSchedules = response.data.data;
        // カレンダー上に選択不可で表示する
        this.optionalAttrs = monitoringSchedules.map((row) => {
          const isPast = moment(row.start_time).isBefore(new Date()); // 過去のスケジュールかどうか（色分けに利用）
          const data = {
            key: `exists_monitoring_schedule_${row.id}`,
            content: {
              style: {
                color: isPast ? 'white' : null,
              },
            },
            highlight: {
              style: {
                'background-color': isPast ? 'var(--gray-main)' : 'rgb(100, 149, 237)',
              },
            },
            popover: {
              label: 'スケジュール登録済み',
              visibility: 'hover',
              hideIndicator: true,
            },
            dates: {
              start: moment(row.start_time).format(SELECTABLE_TIME.DATE_FORMAT),
              end: moment(row.end_time).format(SELECTABLE_TIME.DATE_FORMAT),
            },
          };
          return data;
        });
      } catch (e) {
        alert('モニタリングスケジュール情報の取得に失敗しました');
        throw e;
      }

      // MEMO: 祝日は共通処理側で設定するため、ここでは設定しない

      this.calendar.key = new Date(); // keyを更新することでカレンダーを再描画する
    },
    format(datetime) {
      return moment(datetime).format(DATETIME_FORMAT);
    },
    notStarted(schedule) {
      return moment(new Date()).isBefore(moment(schedule.start_time));
    },
    clickEdit(monitoringSchedule) {
      // 対象のスケジュールデータを編集用にコピー
      this.editingSchedule = {
        id: monitoringSchedule.id,
        startDate: moment(monitoringSchedule.start_time).format(SELECTABLE_TIME.DATE_FORMAT),
        startTime: moment(monitoringSchedule.start_time).format(SELECTABLE_TIME.TIME_FORMAT),
        endDate: moment(monitoringSchedule.end_time).format(SELECTABLE_TIME.DATE_FORMAT),
        endTime: moment(monitoringSchedule.end_time).format(SELECTABLE_TIME.TIME_FORMAT),
      };
    },
    async clickEditSubmit(schedule) {
      this.validationErrorText = null; // バリデーションエラーメッセージをクリア
      const data = {
        id: this.editingSchedule.id,
        start_time: `${moment(this.editingSchedule.startDate).format(SELECTABLE_TIME.DATE_FORMAT)} ${this.editingSchedule.startTime}:00`,
        end_time: `${moment(this.editingSchedule.endDate).format(SELECTABLE_TIME.DATE_FORMAT)} ${this.editingSchedule.endTime}:00`,
        update_user_id: this.user.id,
      };
      // バリデーションチェック
      if (!await this._valid(data)) {
        return;
      }
      // 更新後にモニタリングが即時開始される場合、対象者への即時通知が実行される旨を警告する
      const now = moment(new Date());
      if (now.isAfter(moment(data.start_time)) && now.isBefore(moment(data.end_time))) {
        if (!confirm('モニタリングの即時開始に伴い、対象者全員に即時通知を行います。よろしいですか？')) {
          return;
        }
      }
      this.showLoading();
      try {
        await this.axios({
          method: 'POST',
          url: '/v1/monitoringSchedule/set/update',
          data,
        });
      } catch (e) {
        alert('モニタリングスケジュールの更新に失敗しました');
        throw e;
      } finally {
        this.hideLoading();
      }
      // 削除に成功したら画面上の情報を更新する
      const year = cf.getFiscalYear(schedule.start_time); // TODO 別の年度に変更された場合への対処
      const targetSchedule = this.categorizedSchedules[year].find((row) => row.id === schedule.id);
      targetSchedule.start_time = data.start_time;
      targetSchedule.end_time = data.end_time;
      targetSchedule.update_user_id = data.update_user_id;
      // 呼び出し元にデータの再取得を依頼する
      this.data.callback();
      // 編集モードを解除する
      this.clickEditCancel();
    },
    clickEditCancel() {
      this.editingSchedule = {};
      this.validationErrorText = null; // バリデーションエラーメッセージをクリア
    },
    async clickDelete(monitoringSchedule) {
      this.deleteTargetScheduleId = monitoringSchedule.id; // 選択対象を赤字反転させるために保持
      this.validationErrorText = null; // バリデーションエラーメッセージをクリア

      // ボタンの色反転が見えるように待機（nextTickでは意図通りに動作しなかったため苦肉の策）
      const sleep = (msec) => new Promise((resolve) => setTimeout(resolve, msec));
      await sleep(50);

      if (!confirm('対象のスケジュールを削除します。よろしいですか？')) {
        this.deleteTargetScheduleId = null;
        return;
      }
      this.showLoading();
      try {
        await this.axios({
          method: 'POST',
          url: '/v1/monitoringSchedule/set/delete',
          data: {
            id: monitoringSchedule.id,
          },
        });
      } catch (e) {
        alert('モニタリングスケジュールの削除に失敗しました');
        throw e;
      } finally {
        this.deleteTargetScheduleId = null;
        this.hideLoading();
      }
      // 削除に成功したら対象の情報を画面上から削除する
      const year = cf.getFiscalYear(monitoringSchedule.start_time);
      this.categorizedSchedules[year] = this.categorizedSchedules[year].filter((schedule) => schedule.id !== monitoringSchedule.id);
      // 呼び出し元にデータの再取得を依頼する
      this.data.callback();
    },
    async _valid(data) {
      const fmtYM = 'YYYY-MM';

      // 過去のスケジュールは登録させない
      const now = moment(new Date());
      if (moment(data.start_time).isBefore(now) || moment(data.end_time).isBefore(now)) {
        this.validationErrorText = '開始時刻および終了時刻はともに現在時刻より未来にしてください';
        return false;
      }

      // 時間軸での関係性チェック
      if (!moment(data.start_time).isBefore(moment(data.end_time))) {
        this.validationErrorText = '終了日時は開始日時より未来にしてください';
        return false;
      }

      // // 開始日時と終了日時は同月でないといけない
      // if (moment(data.start_time).format(fmtYM) !== moment(data.end_time).format(fmtYM)) {
      //   this.validationErrorText = '期間が月をまたがないよう設定してください';
      //   return false;
      // }

      // 同一月ですでに登録が行われている場合は登録不可とする
      try {
        const response = await this.axios({
          method: 'GET',
          url: '/v1/monitoringSchedule/get/list',
          params: {
            school_id: this.data.school.id,
            type: 1, // モニタリング
          },
        });
        const exists = response.data.data;
        const checkTargets = exists.filter((row) => row.id !== data.id); // 更新対象のモニタリングスケジュール「以外」を対象にチェックする
        if (checkTargets.some((row) => moment(row.start_time).format(fmtYM) === moment(data.start_time).format(fmtYM))) {
          this.validationErrorText = '対象月のモニタリングは登録済のため、更新できません';
          return false;
        }
      } catch (e) {
        alert('登録チェックのためのモニタリングスケジュール情報の取得に失敗しました');
        throw e;
      }

      return true;
    },
    getTargetAccountTypeLabel(targetAccountTypes) {
      const SELECTABLE_ACCOUNT_TYPES = [
        1, // 生徒・プログラム対象者
        3, // 教職員・企業担当者
      ];
      const accountTypes = JSON.parse(targetAccountTypes);
      if (accountTypes.length >= SELECTABLE_ACCOUNT_TYPES.length) {
        return '利用者全員';
      }
      const masterLabel = this.data.school.is_company ? this.helper.master.labels.users.company_account_type : this.helper.master.labels.users.account_type;
      return accountTypes.map((type) => masterLabel[type]).join('、');
    },
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },
    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },
    hideModal() {
      this.$store.dispatch('modal/contents/hideModal', null, { root: true });
    },
  },
};
</script>

<style lang="scss" module>
.detail {
  li, .detail_div {
    display: flex;
    align-items: flex-start;
    .label {
      width: 150px;
      margin-right: .5rem;
    }
    .content {
      margin-left: 20px;
      margin-top: 3px;
      flex: 1;
      word-break: break-all;
    }
    &:not(:first-child) {
      margin-top: .5rem;
    }
  }
  @include sm-view {
    font-size: 14px;
    li {
      display: block;
      .label {
        width: 100%;
        margin: 0 0 .5em;
        text-align: left;
        padding: .5em;
      }
    }
  }
}
.select {
  margin-left: .5rem;
  padding: 6px;
  border-radius: 8px;
  background-color: var(--gray-sub);
  border: none;
  outline: none;
  $background: var(--gray-sub);
  font-size: 14px;
  &:-webkit-autofill {
    box-shadow: 0 0 0 100px $background inset;
  }
  &.plane {
    background-color: transparent;
  }
}
.iconsWrapper {
  margin-left: 1rem;
  > label {
    cursor: pointer;
    color: var(--gray-side);
    > i {
      font-size: 20px;
      margin-right: .5rem;
    }
  }
}
.dateSeparator {
  margin: 0 .5rem;
}
.btnWrapper {
  margin-left: 1rem;
  > button {
    &:not(:last-child) {
      margin-right: .5rem;
    }
  }
}

.alert_text {
  margin-left: .5rem;
  font-size: .8em;
  color: red;
}

.close {
  position: absolute;
  top: 30px;
  right: 30px;
  width: 50px;
  height: 50px;
  background-color: var(--gray-main);
  border-radius: 50%;
  cursor: pointer;
  &::before, &::after {
    content: '';
    width: 3px;
    height: 30px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
  }
  &::before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  &::after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  @include sm-view {
    width: 20px;
    height: 20px;
    &::before, &::after {
      content: '';
      width: 2px;
      height: 13px;
      background-color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
    }
  }
}

.fontRed {
  color: red;
}
</style>
